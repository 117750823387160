import { Box } from "@mui/material";

const Skeleton = ({
  height,
  width,
  borderRadius = "var(--shape-md-round)"
}) => (
  <Box
    height={height}
    width={width}
    borderRadius={borderRadius}
    bgcolor="var(--surface-container-low)"
    sx={{ animation: "pulse 4s ease-in-out 1s infinite" }}
  />
);

export default Skeleton;
