"use client";
import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { Box, Container, Fade, Slide } from "@mui/material";

import { useStore, observer } from "../../src/service/mobx";
import Agent from "../../src/component/Agent/root";
import PaneRight from "../../src/component/PaneRight";

function Layout({ children }) {
  const [transitioning, setTransition] = useState(false);
  const { device, layout, reader } = useStore();
  const pathname = usePathname();
  const transitionTimingFunction = layout.mountContent
    ? "var(--motion-easing-emphasizedDecelerate) !important"
    : "var(--motion-easing-emphasizedAccelerate) !important";
  const twoColumns =
    (layout.mountAgent && layout.mountContent) || transitioning;

  // prevent page from scroll when agent unmounted, keeping agent scroll spot
  useEffect(() => {
    if (pathname !== "/" && layout.mountAgent === false) {
      document.body.style.overflowY = "hidden";

      return () => document.body.style.removeProperty("overflow-y");
    }
  }, [pathname, layout.mountAgent]);

  useEffect(() => {
    // if someone quickly navigates and interrupts a transition, then
    // always start transition === false, for fresh state
    setTransition(false);
  }, [pathname]);

  return (
    <Container
      disableGutters
      maxWidth={
        reader.whiteLabelled ? "large" : twoColumns ? false : layout.maxWidth
      }
      sx={{
        zIndex: device.isPhone ? undefined : "var(--zIndex-drawer)",
        display:
          reader.whiteLabelled === false && pathname !== "/"
            ? "grid"
            : undefined,
        transition: "opacity 1s",
        opacity: layout.checkedWhiteLabel ? 1 : 0,
        px: twoColumns
          ? { compact: 0, expanded: 2, large: 3 }
          : { compact: 0, expanded: 3, large: 0 },
        gap: twoColumns ? { compact: 2, expanded: 4, large: 6 } : undefined,
        gridTemplateColumns: `${
          twoColumns && device.isPhone === false ? "minmax(0, 50vw)" : "1fr"
        } ${twoColumns && device.isPhone === false ? "minmax(0, 50vw)" : "0fr"}`
      }}
    >
      {reader.whiteLabelled ? (
        <Box
          width="100%"
          minHeight="100vh"
          p={{ compact: 2, expanded: 3 }}
          bgcolor="var(--surface-container-lowest)"
        >
          {children}
        </Box>
      ) : (
        <>
          {pathname === "/" ? null : <Agent />}
          {device.isPhone || pathname === "/" ? (
            children
          ) : (
            <Slide
              unmountOnExit
              direction="left"
              timeout={layout.transitionDuration}
              in={layout.mountContent && device.isPhone === false}
            >
              <Box
                width="100%"
                maxWidth="50vw"
                zIndex={1}
                display="flex"
                sx={{ transitionTimingFunction }}
              >
                <Fade
                  in={layout.mountContent && device.isPhone === false}
                  timeout={layout.transitionDuration}
                  onEnter={
                    device.isPhone ? undefined : () => setTransition(true)
                  }
                  onExited={
                    device.isPhone
                      ? undefined
                      : () =>
                          setTimeout(
                            setTransition,
                            layout.transitionDuration,
                            false
                          )
                  }
                >
                  <Box width="100%" sx={{ transitionTimingFunction }}>
                    <PaneRight>{children}</PaneRight>
                  </Box>
                </Fade>
              </Box>
            </Slide>
          )}
        </>
      )}
    </Container>
  );
}

export default observer(Layout);

export function MountAsBottomSheet({ children, key, isWhiteLabelled = false }) {
  const { device, bottomSheet } = useStore();
  const router = useRouter();

  useEffect(() => {
    if (device.isPhone && isWhiteLabelled === false) {
      bottomSheet.set.peek(true);
      bottomSheet.set.sx({ height: "95vh" });
      bottomSheet.set.onClose(() => {
        if (bottomSheet.open) {
          bottomSheet.set.open();
        }

        setTimeout(() => {
          router.push("/agent", { scroll: false });
          bottomSheet.reset();
        }, 300);
      });

      bottomSheet.configure(() => children);
    }
  }, [bottomSheet, device.isPhone, children, router, key, isWhiteLabelled]);

  return device.isPhone && isWhiteLabelled === false ? null : children;
}
