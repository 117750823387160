"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { InputBase, Stack } from "@mui/material";
import {
  ArrowUpwardRounded as SendOffIcon,
  ArrowUpwardRounded as SendOnIcon,
  StopRounded as StopIcon,
  LockRounded as LockedIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../../service/mobx";
import ButtonToggle from "../../Button/Toggle";
import Suggestions from "./Suggestions";

function Input({ locked }) {
  const [input, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const { agent, reader, user } = useStore();
  const pathname = usePathname();
  const disabled =
    agent.threadId === undefined ||
    ((pathname.startsWith("/docs") || pathname.startsWith("/read")) &&
      reader.viewing === "paper" &&
      agent.paperHasBeenRead === false);

  const submit = () => {
    if (locked) {
      locked();
    } else if (input.trim()) {
      agent.chat(input);
      setInput("");
    }
  };

  usePaper(pathname, setPlaceholder);

  return (
    <Stack
      position="sticky"
      bottom={0}
      useFlexGap
      spacing={0.5}
      // direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="var(--shape-xl-top)"
      mb={{ compact: 0, expanded: -2, large: -3 }}
      sx={theme => ({
        pl: 2,
        pr: 1,
        py: 1,
        border: 1,
        borderColor: "var(--outline-variant)",
        boxShadow: "var(--elevation-2)",
        width: { compact: "100vw", expanded: "100%" },
        transition: theme.transitions.create("all"),
        bgcolor: "var(--surface-container-lowest)",
        "&:hover": {
          bgcolor: "var(--surface-container-low)"
        }
      })}
    >
      <InputBase
        fullWidth
        value={input}
        disabled={disabled}
        multiline
        maxRows={8}
        minRows={3}
        placeholder={placeholder}
        sx={{
          position: "relative",
          typography: "titleMd",
          color: "var(--surface-on-color)",
          ".Mui-disabled": {
            fontStyle: "italic",
            WebkitTextFillColor: "var(--secondary-on-container)"
          },
          "& .MuiInputBase-input::placeholder": {
            opacity: 1,
            typography: "bodyLg",
            color: "var(--secondary-color)"
          }
        }}
        inputProps={{
          sx: { color: "var(--surface-on-color)" }
        }}
        onChange={event => setInput(event.target.value)}
        onKeyUp={event => {
          if (event.shiftKey === false && event.key === "Enter" && input) {
            submit();
          }
        }}
      />
      <Stack
        spacing={1}
        width="100%"
        bgcolor="inherit"
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
      >
        <ButtonToggle
          aria-label="Submit"
          disabled={agent.running === false && (disabled || input === "")}
          OffIcon={locked ? LockedIcon : SendOffIcon}
          OnIcon={agent.running ? StopIcon : locked ? LockedIcon : SendOnIcon}
          selected={
            (agent.running === false && (disabled || input === "")) === false
          }
          sx={{ flex: 1 }}
          onClick={() => {
            if (agent.running) {
              const [{ runId }] = agent.history;

              fetch(
                `/api/agent?threadId=${user.profile.threadId}&runId=${runId}`,
                { method: "PATCH" }
              );
              agent.set.running(false);
            } else {
              submit();
            }
          }}
        />
        <Suggestions locked={locked} />
      </Stack>
    </Stack>
  );
}

export default observer(Input);

function usePaper(pathname, setSpeech) {
  const { agent, reader } = useStore();
  // const isDiscover = pathname === "/";
  const isPaperPage =
    pathname.startsWith("/docs/") || pathname.startsWith("/read/");

  useEffect(() => {
    if (isPaperPage) {
      if (agent.paperHasBeenRead) {
        setSpeech(
          "Have a question on " + reader.paperNode?.title?.split(":")[0] + "?"
        );
      } else if (reader.viewing === "paper") {
        setSpeech("Reading the paper for you...");
      } else {
        setSpeech("Ask Bytez AI...");
      }
    } else {
      setSpeech("Ask Bytez about AI papers and models...");
    }
  }, [reader, setSpeech, isPaperPage, reader.viewing, agent.paperHasBeenRead]);
}
