"use client";
// import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { observer } from "../../service/mobx";
import Input from "./Input";
import Messages from "./Messages";
import { useFeatureGate } from "./ClientHooks";

function AgentClient() {
  // const { layout } = useStore();
  const locked = useFeatureGate();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Messages />
      <Input locked={locked} />
    </Box>
  );
}

export default observer(AgentClient);
