"use client";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  ArrowForward as RunIcon,
  Battery20Outlined as IconStep1,
  Battery30Outlined as IconStep2,
  Battery60Outlined as IconStep4,
  Battery90Outlined as IconStep6,
  BatteryChargingFull as IconStep7
} from "@mui/icons-material";
import Bytez from "bytez.js";

import { observer, useStore } from "../../../service/mobx";
import { fetchFromApiServerRaw } from "../../../service/graph";
import ButtonLoading from "../../Button/Loading";
import ToolTip from "../../Tooltip";
import useSignInDialog from "../../Dialog/dialogs/appWide/Login";
import useIO, { InputTextField } from "./useIO";
import useDialogUpgrade from "../../Dialog/dialogs/appWide/Upgrade";

function Widget({ model, small = false }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [input, setInput] = useState();
  const [output, setOutput] = useState();
  const { user } = useStore();
  const { label, Input, Output, Examples, comingSoon } = useIO(model);
  const upgradeDialog = useDialogUpgrade();
  const openDialog = useFeatureGate(upgradeDialog);
  const runModel = useCallback(async () => {
    try {
      setLoading(true);

      const stream = model.task === "text-generation";
      const res = await fetchFromApiServerRaw({
        path: "app/widget",
        body: {
          input,
          stream,
          uid: user.uid,
          customerId: user.customerId,
          key: user.key,
          task: model.task,
          modelId: model.modelId,
          timeout: 120
        }
      });
      const isNotCompressedJSON = res.headers.has("content-type") === false;

      if (stream && isNotCompressedJSON) {
        await streamText(res, setOutput);
      } else {
        const json = await res.json();

        console.log("output received", json);

        if (json.error) {
          setError(json.error);
          setOutput();

          if (json.error.startsWith("Out of free monthly credits")) {
            upgradeDialog();
          }
        } else {
          setError();
          setOutput(
            json?.output?.text ??
              json?.[0]?.generated_text ??
              json?.output?.[0]?.generated_text ??
              json?.output?.[0]?.summary_text ??
              json?.[0]?.summary_text ??
              json?.output?.[0]?.translation_text ??
              json?.[0]?.translation_text ??
              json?.output_png ??
              json?.output_mp4 ??
              json?.output_wav ??
              json?.output ??
              json
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user, model, upgradeDialog, input]);

  return (
    <Box
      p={2}
      bgcolor="var(--surface-container-low)"
      borderRadius={"var(--shape-md-round)"}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {small ? null : (
            <Typography
              color="var(--surface-on-color)"
              typography={{ compact: "titleMd", expanded: "titleLg" }}
            >
              {comingSoon ? "Coming soon ✨" : label}
            </Typography>
          )}
          <Stack spacing={1} direction="row" alignItems="center">
            <InstanceStatus
              apiKey={user.key}
              modelId={model.modelId}
              setLoading={setLoading}
              noOutput={output === undefined}
            />
            <ButtonLoading
              label="Run"
              labelLoading="Running"
              loading={loading}
              IconEnd={RunIcon}
              disabled={
                comingSoon ||
                ((input === undefined ||
                  (input.constructor === Object &&
                    Object.values(input).some(value => !value))) &&
                  model.task.startsWith("unconditional") === false) ||
                (user.isAnonymous === false && user.syncedWithStripe === false)
              }
              onClick={openDialog || runModel}
            />
          </Stack>
        </Stack>
        <Input
          task={model.task}
          input={input}
          setInput={setInput}
          loading={loading}
          output={output}
        />
        {small ? null : <Examples setInput={setInput} loading={loading} />}
        {error ? <ErrorMessage error={error} /> : null}
        <Output task={model.task} output={output} />
      </Stack>
    </Box>
  );
}

export default observer(Widget);

function InstanceStatus({ modelId, apiKey, noOutput, setLoading }) {
  const [status, setStatus] = useState("DELETING");
  const { analytics, snackbar, user } = useStore();
  const isOff = status !== "RUNNING";
  const color = `var(--${
    isOff && noOutput ? "colors-info-40" : "colors-success-40"
  })`;
  const Icon =
    {
      DELETING: IconStep1,
      STARTING: IconStep2,
      INSTANTIATING: IconStep4,
      STARTING_CONTAINER: IconStep6,
      RUNNING: IconStep7
    }[status] || IconStep1;

  useEffect(() => {
    if (apiKey) {
      const unsubscribe = user.data.item.watch(
        "instances",
        modelId.toLowerCase().replace(/[\W_]/g, "-"),
        doc => {
          const cluster = doc.data();

          setLoading(cluster?.locked ?? false);
          setStatus(
            cluster === undefined
              ? "DELETING"
              : status => {
                  if (cluster.status === "UNSET") {
                    cluster.status = "DELETING";
                  }

                  if (status !== cluster.status) {
                    setTimeout(() =>
                      snackbar.notify({
                        text: `${modelId} - ${cluster.status
                          .toLowerCase()
                          .replace("_", " ")}`
                      })
                    );
                  }

                  return cluster.status;
                }
          );
        }
      );

      return () => {
        unsubscribe();
        new Bytez(apiKey, process.env.NODE_ENV !== "production")
          .model(modelId)
          .delete();
      };
    }
  }, [analytics, snackbar, user, setLoading, modelId, apiKey]);

  return (
    <ToolTip title="Model is off, loading, or on" placement="left">
      <Stack
        p={1}
        px={1.5}
        spacing={1}
        direction="row"
        alignItems="center"
        sx={theme => ({
          cursor: "default",
          borderRadius: "var(--shape-sm-round)",
          bgcolor: theme.alpha(
            `var(--${isOff ? "colors-info-40" : "colors-success-40"})`,
            0.07
          )
        })}
      >
        <Icon
          sx={{
            color,
            width: { compact: 16, expanded: 20 },
            height: { compact: 16, expanded: 20 }
          }}
        />
        <Typography
          color={color}
          typography={{ compact: "labelSm", expanded: "labelMd" }}
        >
          {`${
            status === "RUNNING" ? "on" : Icon === IconStep1 ? "off" : "loading"
          }`}
        </Typography>
      </Stack>
    </ToolTip>
  );
}

const ErrorMessage = ({ error }) => (
  <InputTextField
    multiline
    value={error}
    label="error"
    inputProps={{ sx: { color: "var(--error-on-container)" } }}
    sx={{
      borderRadius: "var(--shape-xs-round)",
      bgcolor: "var(--error-container)",
      "& label, & .Mui-focused": {
        color: "var(--error-color) !important"
      },
      "& ::after, & :hover::after": {
        borderBottomColor: "var(--error-color) !important"
      },
      "& ::before, & :hover::before": {
        borderBottomColor: "var(--error-container) !important"
      }
    }}
  />
);

function useFeatureGate(upgradeDialog) {
  const [hasFreeCredits, setHasCredits] = useState(false);
  const { analytics, user } = useStore();
  const openSignInDialog = useSignInDialog(
    "Running models is free for the community"
  );

  useEffect(() => {
    setHasCredits(analytics.meters.units < 100);
  }, [analytics.meters]);

  return user.isAnonymous
    ? openSignInDialog
    : user.premium || hasFreeCredits
    ? undefined
    : upgradeDialog;
}
async function streamText(res, callback) {
  try {
    let gist = "";
    const readableStream = res
      .clone()
      .body.pipeThrough(new TextDecoderStream())
      .getReader();

    do {
      var { done, value } = await readableStream.read();

      if (done === false) {
        gist += value;

        callback(gist);
      }
    } while (done === false);
  } catch (error) {
    console.error(error);
  }
}
