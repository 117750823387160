import { forwardRef, useEffect, useState, Fragment } from "react";
import { useRouter } from "next/navigation";
import {
  Autocomplete,
  Box,
  Collapse,
  TextField,
  Typography
} from "@mui/material";
import { Virtuoso } from "react-virtuoso";

import { observer, useStore } from "../../../../service/mobx";
import MenuItem from "../../Item";
import { paperSx } from "../..";

function SearchMenuTasks({ value, onChange }) {
  const [open, setOpen] = useState(false);
  const [taskList, setTasks] = useState([]);
  const [options, setOptions] = useState([]);
  const { analytics, bottomSheet, tasks, search } = useStore();
  const router = useRouter();

  useEffect(() => {
    const taskList =
      search.filterType === "papers" ? tasks._set : tasks._setModel;

    setTasks([...taskList]);
  }, [tasks._set, tasks._setModel, search.filterType]);

  useEffect(() => {
    bottomSheet.set.sx({ height: "65vh", zIndex: 1200 });
  }, [bottomSheet]);

  useEffect(() => {
    const selectedTasks =
      value === undefined ? search.filterTask : new Set(value);

    setOptions(
      taskList
        .map(task => [
          task,
          selectedTasks.has(task) ? (value ? "Following" : "Selected") : "Task"
        ])
        .sort(([task]) => (selectedTasks.has(task) ? -1 : 0))
    );
  }, [taskList, value, search.filterTask]);

  useEffect(() => {
    setTimeout(setOpen, 500, true);
  }, []);

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      popupIcon={null}
      size="small"
      options={options}
      value={
        value?.map(task => [task, "Following"]) ??
        search.filterTaskArray.map(task => [task, "Selected"])
      }
      groupBy={groupBy}
      onChange={
        onChange ||
        ((_, values) => {
          const tasks = values.map(([task]) => task);

          search.updateUrlParams(router, { task: tasks });
          analytics.track.event("Search Filter", {
            entity: { type: "task", value: tasks.length ? tasks : false }
          });
        })
      }
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      noOptionsText={null}
      isOptionEqualToValue={isOptionEqualToValue}
      renderTags={() => null}
      slots={{ paper: PaperComponent, listbox: ListboxComponent }}
      renderOption={renderOption}
      renderGroup={renderGroup}
      sx={{ minWidth: 280 }}
    />
  );
}

export default observer(SearchMenuTasks);

const renderInput = params => (
  <TextField {...params} variant="filled" label="Search tasks" />
);
const isOptionEqualToValue = ([a], [b]) => a === b;
const renderOption = (props, [option], { selected }) => (
  <MenuItem label={option} selected={selected} {...props} key={option} />
);
const groupBy = ([, group]) => group;
const getOptionLabel = ([option]) => option;
const renderGroup = ({ children, group, key }) => (
  <Fragment key={key}>
    <Typography
      gutterBottom
      pl={1.5}
      key={`group-${group}`}
      variant="labelLgProminent"
    >
      {group}
    </Typography>
    {children}
  </Fragment>
);

const ListboxComponent = forwardRef(function ListboxComponent(
  { children, ownerState, ...props },
  ref
) {
  const itemData = [];

  children.forEach(group => {
    group.props.children.forEach(child => {
      if (Array.isArray(child)) {
        itemData.push(...child);
      } else {
        itemData.push(child);
      }
    });
  });

  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        maxHeight: "unset !important",
        overflow: "hidden !important"
      }}
    >
      <Virtuoso
        overscan={10}
        computeItemKey={computeItemKey}
        data={itemData}
        style={{ height: "49vh" }}
        itemContent={itemContent}
      />
    </Box>
  );
});
const itemContent = (_, data) => data;
const computeItemKey = (_, data) => data.key;
const PaperComponent = forwardRef(function PaperComponent(props, ref) {
  return (
    <Collapse
      appear
      in
      timeout={300}
      sx={{
        ...paperSx,
        boxShadow: { compact: "unset", expanded: paperSx.boxShadow },
        bgcolor: {
          compact: "var(--surface-container-highest)",
          expanded: paperSx.bgcolor
        }
      }}
    >
      <div ref={ref} {...props} />
    </Collapse>
  );
});
