"use client";
import { useCallback, useLayoutEffect, useState } from "react";
import { IconButton, Stack, Fade, Box } from "@mui/material";
import { ChevronLeftRounded as ScrollIcon } from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";
import { filterChipScrollSx } from "../../view/search/Filters";

function HorizontalChips({ chips }) {
  const [ref, setRef] = useState();
  const [hide, setHide] = useState({ left: true, right: false });
  const { device } = useStore();
  const isPhone = device.measured === false || device.isPhone;

  const hideScrollButtons = useCallback(() => {
    const { scrollLeft, scrollWidth, clientWidth } = ref;
    const maxScrollLeft = scrollWidth - clientWidth;

    setHide({
      left: scrollLeft <= 0,
      right: maxScrollLeft <= Math.ceil(scrollLeft)
    });
  }, [ref]);

  useLayoutEffect(() => {
    if (ref) {
      let eventListener;
      let timeoutId2;
      let timeoutId1 = setTimeout(() => {
        hideScrollButtons();
        eventListener = () => {
          clearTimeout(timeoutId2);
          timeoutId2 = setTimeout(hideScrollButtons, 1e3);
        };

        window.addEventListener("resize", eventListener, { passive: true });
      }, 1e3);

      return () => {
        clearTimeout(timeoutId1);
        clearTimeout(timeoutId2);
        window.removeEventListener("resize", eventListener);
      };
    }
  }, [ref, hideScrollButtons, chips]);

  return (
    <Stack
      useFlexGap
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="inherit"
    >
      <ScrollButton hide={hide} isPhone={isPhone} ref={ref} />
      <Stack
        ref={setRef}
        useFlexGap
        spacing={0.5}
        direction="row"
        py={1}
        px={{ compact: 2, expanded: 1 }}
        mx={{ compact: "-16px !important", expanded: "0 !important" }}
        sx={{
          zIndex: 0,
          overflowX: "auto",
          whiteSpace: "nowrap",
          ...filterChipScrollSx
        }}
        onScroll={hideScrollButtons}
        onClick={event => {
          const chip = event.target;
          const chipRect = chip.getBoundingClientRect();
          const containerRect = ref.getBoundingClientRect();

          // Get the chip and container center positions
          const chipCenter = (chipRect.left + chipRect.right) / 2;
          const containerCenter =
            (containerRect.left + containerRect.right) / 2;

          // Calculate how much to scroll to center the chip
          const scrollAmount = chipCenter - containerCenter;

          // Scroll the container by the calculated amount
          ref.scrollBy({
            behavior: "smooth",
            left: scrollAmount
          });
        }}
      >
        {chips}
      </Stack>
      <ScrollButton endPosition hide={hide} isPhone={isPhone} ref={ref} />
    </Stack>
  );
}

export default observer(HorizontalChips);

const ScrollButton = ({ endPosition = false, isPhone, hide, ref }) => (
  <Fade
    mountOnEnter={isPhone}
    unmountOnExit={isPhone}
    in={isPhone === false && hide[endPosition ? "right" : "left"] === false}
    timeout={{ enter: 500, exit: 200 }}
  >
    <Box
      sx={theme => ({
        bgcolor: "inherit",
        pr: endPosition ? undefined : 1,
        pl: endPosition ? 1 : undefined,
        left: endPosition ? undefined : 0,
        right: endPosition ? 0 : undefined,
        zIndex: 10,
        top: "50%",
        transitionTimingFunction: "var(--motion-easing-emphasized)",
        position: "absolute",
        transform: "translateY(-50%)",
        ...theme.fadeEdge(endPosition ? "left" : "right", 70)
      })}
    >
      <IconButton
        size="small"
        onClick={() => {
          ref.scrollBy({
            behavior: "smooth",
            left: endPosition
              ? ref.offsetWidth
              : -Math.ceil(ref.offsetWidth + 10)
          });
        }}
      >
        <ScrollIcon
          sx={{
            color: "var(--secondary-color)",
            transform: endPosition ? "rotate(180deg)" : undefined
          }}
        />
      </IconButton>
    </Box>
  </Fade>
);
