"use client";
import { useEffect, useState } from "react";
import { Popper, Fade, Box } from "@mui/material";

import { useStore, observer } from "../../../src/service/mobx";

function Popup({
  eventName = "",
  disable = false,
  Component,
  children,
  onEntering,
  minWidth = 320,
  maxWidth = 480,
  controlled = false,
  disableMobile = false,
  // enterTouchDelay = 500,
  ...props
}) {
  const [mouseOver, setMouseOver] = useState();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { analytics, bottomSheet, device } = useStore();
  const isOpen = open || props.open;
  // const isTouch =
  //   device.isPhone || (device.isTablet && navigator.maxTouchPoints !== 0);

  useEffect(() => {
    if (mouseOver) {
      var id = setTimeout(setOpen, 750, true);

      return () => clearTimeout(id);
    } else if (mouseOver === false) {
      id = setTimeout(setOpen, 500, false);

      return () => clearTimeout(id);
    }
  }, [mouseOver]);

  useEffect(() => {
    if (isOpen) {
      if (device.isPhone) {
        bottomSheet.configure(Component);
      }

      analytics.track.event(`Popup ${eventName}`.trim());
    }
  }, [analytics, bottomSheet, device.isPhone, Component, eventName, isOpen]);

  return disable || (disableMobile && device.isPhone) ? (
    children
  ) : (
    <span
      ref={setAnchorEl}
      onMouseEnter={controlled ? undefined : () => setMouseOver(true)}
      onMouseLeave={controlled ? undefined : () => setMouseOver(false)}
      // enterTouchDelay={enterTouchDelay}
      // onTouchEndCapture={
      //   isTouch
      //     ? event => {
      //         if (event.cancelable) {
      //           event.preventDefault();
      //         }
      //       }
      //     : undefined
      // }
    >
      {children}
      {device.isPhone ? null : (
        <Popper
          transition
          open={isOpen}
          anchorEl={anchorEl}
          placement="top"
          popperOptions={{ strategy: "fixed" }}
          sx={theme => ({
            minWidth,
            maxWidth,
            zIndex: theme.zIndex.modal - 1,
            borderRadius: "var(--shape-md-round)",
            boxShadow: isOpen ? "var(--elevation-4)" : undefined
          })}
          {...props}
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              sx={theme =>
                theme.motion.transition[TransitionProps.in ? "enter" : "exit"]
                  .perm
              }
            >
              <Box>
                <Component />
              </Box>
            </Fade>
          )}
        </Popper>
      )}
    </span>
  );
}

export default observer(Popup);
/*
  <Popper

      placement={placement}
      leaveDelay={500}
      // enterDelay={1.5e3}
      title={isTouch ? "" : component || <Component />}
      onOpen={
        isTouch
          ? event => {
              if (!disableMobile) {
                if (event.cancelable) {
                  event.preventDefault();
                }
                bottomSheet.configure(Component);
                analytics.track.event(`Popup ${eventName}`.trim());

                return false;
              }
            }
          : event => {
              const element = event.target;
              const percentX =
                element?.offsetParent?.offsetLeft /
                element?.offsetParent?.offsetParent?.offsetWidth;

              console.log(element);
              setPlacement(percentX < 0.5 ? "right" : "left");
              analytics.track.event(`Popup ${eventName}`.trim());
            }
      }
      enterTouchDelay={enterTouchDelay}
      onTouchEndCapture={
        isTouch
          ? event => {
              if (event.cancelable) {
                event.preventDefault();
              }
            }
          : undefined
      }
      slotProps={{
        popper: {
          popperOptions: {
            strategy: "fixed",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [2000, 2000] // 200px to the right, 0px vertically
                }
              }
            ]
          }
        }
      }}
      componentsProps={{
        transition: {
          timeout: { enter: 300, exit: 250 },
          // onExited: () => remount(),
          // onEntered: () => setTimeout(remount, 300, true),
          onEntering
        },
        popper: {
          popperOptions: {
            strategy: "fixed",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [2000, 2000] // 200px to the right, 0px vertically
                }
              }
            ]
          },
          sx: theme => ({
            zIndex: theme.zIndex.tooltip + 1
            // transition: theme.transitions.create("all")
          })
        },
        // popper: remounted
        //   ? {
        //       popperOptions: {
        //         strategy: "fixed",
        //         modifiers: [
        //           {
        //             name: "offset",
        //             options: {
        //               offset: [2000, 2000] // 200px to the right, 0px vertically
        //             }
        //           }
        //         ]
        //       },
        //       sx: theme => ({
        //         zIndex: theme.zIndex.tooltip + 1,
        //         transition: theme.transitions.create("all")
        //       })
        //     }
        //   : {
        //       popperOptions: {
        //         strategy: "fixed",
        //         modifiers: [
        //           {
        //             name: "offset",
        //             options: {
        //               offset: [2000, 2000] // 200px to the right, 0px vertically
        //             }
        //           }
        //         ]
        //       }
        //     },
        tooltip: {
          sx: isTouch
            ? { display: "none" }
            : theme => ({
                p: 0,
                minWidth,
                maxWidth,
                overflow: "overlay",
                boxShadow: theme.elevation[2],
                borderRadius: "var(--shape-md-round)",
                bgcolor: "var(--surface-container-lowest)"
              })
        }
      }}
      {...props}
    >
      {children}
    </Tooltip>
*/
