//
// import { graph } from "../../service/graph-req";
import Client from "./Client";
// import ClientHooks from "./ClientHooks";
// import { textRank } from "../../service/mobx/utilities";
// import { convertToID } from "../../service/graph";
import Hooks from "../../../src/view/agent/Hooks";

export default function Agent() {
  //

  return (
    <>
      <Client />
      <Hooks />
    </>
  );
}
