"use client";
import { Suspense, useEffect, useRef, useState } from "react";
import { Box, Collapse, Stack, Divider, Typography } from "@mui/material";
import {
  Settings as TuneIcon,
  Search as SearchIcon,
  ExpandLess as ShowLessIcon,
  ExpandMore as ShowMoreIcon
} from "@mui/icons-material";
import { Masonry } from "masonic";
import useSWR from "swr";

import { useStore, observer } from "../../../service/mobx";
import { fetcher } from "../../../service/graph";
import { PaperCardSize3 } from "../../../component/Card/Paper";
import { CardProductSize2 } from "../../../component/Card/Product";
import { ModelCardSize3 } from "../../../component/Card/Model";
import { CardExternalCodeSize2 } from "../../../component/Card/External";
import ChipFilter from "../../../component/Chip/Filter";
import ButtonOutlined from "../../../component/Button/Outlined";
import HorizontalChips from "../../../component/Chip/Horizontal";
import useSignInDialog from "../../../component/Dialog/dialogs/appWide/Login";
import ButtonText from "../../../component/Button/Text";
import MenuTask from "../../../component/Menu/menus/Search/Task";
import Skeleton from "../../../component/Skeleton";

function Following() {
  const [following, setFollowing] = useState([]);
  const [task, setTask] = useState("all");
  const { device, layout, user, stars } = useStore();
  const ref = useRef();
  const sections = [
    { sort: "trending", entity: "models" },
    { sort: "trending", entity: "papers" },
    { sort: "new", entity: "papers" },
    { sort: "new", entity: "models" },
    { sort: "popular", entity: "papers" },
    { sort: "popular", entity: "models" }
  ];

  useEffect(() => {
    if (user.loaded) {
      const notPhone = device.isPhone === false;

      setFollowing([
        { id: "all", name: "all" },
        ...(user.isAnonymous
          ? [
              notPhone
                ? { id: "text-generation", name: "text generation" }
                : undefined,
              { id: "chat", name: "chat" },
              { id: "text-to-image", name: "image generation" },
              notPhone
                ? { id: "object-detection", name: "object detection" }
                : undefined
            ].filter(defined => defined)
          : stars.tasks)
      ]);

      return () => setFollowing([]);
    }
  }, [user, user.loaded, device.isPhone, stars.tasks]);

  return (
    <Stack spacing={5} mt={20} pb={5}>
      <Box
        px={{ compact: 2, expanded: 0 }}
        top={{ compact: 4, expanded: 0 }}
        position="sticky"
        bgcolor="var(--surface-dim)"
        zIndex={`calc( var(--zIndex-appBar) - ${
          device.isPhone && layout.navOpen ? 1 : 0
        } )`}
        sx={theme => ({
          ...theme.fadeEdge(),
          transition:
            device.isPhone && layout.navOpen ? undefined : "z-index 1s"
        })}
      >
        <HorizontalChips
          bgcolor="var(--surface-dim)"
          chips={[
            user.loaded ? (
              <TuneYourFeed key="tune" />
            ) : (
              <Skeleton
                key="tune"
                width={150}
                height={32}
                borderRadius="var(--shape-sm)"
              />
            ),
            ...(stars.tasks.length === 0
              ? [
                  <div key="all">
                    <Skeleton
                      width={66}
                      height={32}
                      borderRadius="var(--shape-sm)"
                    />
                  </div>,
                  ...stars.starredTasks.map(({ id }) => (
                    <div key={id}>
                      <Skeleton
                        height={32}
                        borderRadius="var(--shape-sm)"
                        width={parseInt(Math.random() * (120 - 60) + 60)}
                      />
                    </div>
                  ))
                ]
              : following.map(_task => (
                  <ChipFilter
                    key={_task.id}
                    label={_task.name}
                    small={device.isPhone}
                    selected={task === _task.name}
                    onClick={() => {
                      setTask(_task.name);
                      ref.current.scrollIntoView();
                    }}
                  />
                )))
          ]}
        />
      </Box>
      <Box ref={ref} overflow="hidden" px={{ compact: 2, expanded: 0 }}>
        {sections.map(({ sort, entity }) => (
          <Suspense key={sort + entity + task}>
            <Section
              key={sort + entity + task}
              task={task}
              sort={sort}
              entity={entity}
            />
          </Suspense>
        ))}
      </Box>
    </Stack>
  );
}

export default observer(Following);

const Section = observer(function Section({ task, sort, entity }) {
  const [skeleton] = useState(
    new Array(6).fill().map(() => [Math.random() * (92 - 64) + 92])
  );
  const [showMore, setShowMore] = useState(false);
  const [collapsedSize, setCollapsedSize] = useState(0);
  const ref = useRef();
  const { data, isLoading } = useSWR(
    `/api/following/${task}/${entity}/${sort}`,
    fetcher
  );
  const { device } = useStore();
  const label = `${sort} ${entity}`;
  const columnCount = {
    compact: 1,
    medium: 2,
    expanded: 1,
    large: 2,
    extraLarge: 2
  }[device.size];
  const previewSize = device.isPhone ? 3 : 6;

  function measureCollapseSize() {
    const items = [
      ...ref.current.nextSibling.firstChild.firstChild.firstChild.children
    ]
      .map(element => [
        {
          top: parseInt(element.style.top),
          left: parseInt(element.style.left)
        },
        element.getBoundingClientRect().height
      ])
      .sort(([a], [b]) => (a.top === b.top ? a.left - b.left : a.top - b.top))
      .slice(0, previewSize);
    let collapsedSize = 0;

    for (const [{ top }, height] of items) {
      collapsedSize = Math.max(collapsedSize, top + height);
    }

    setCollapsedSize(collapsedSize + 72);
  }

  return device.measured === false ? null : isLoading ? (
    <>
      <Stack
        pb={2}
        pt={7}
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          height={32}
          width={184}
          borderRadius="var(--shape-xs-round)"
        />
        <Skeleton height={32} width={192} borderRadius="var(--shape-round)" />
      </Stack>
      <Masonry
        rowGutter={8}
        columnGutter={8}
        maxColumnCount={2}
        overscanBy={previewSize}
        columnCount={columnCount}
        items={skeleton.slice(0, previewSize)}
        render={({ data: [height] }) => (
          <Box width="100%">
            <Skeleton width="100%" height={height} />
          </Box>
        )}
      />
    </>
  ) : data?.length ? (
    <>
      <Stack
        pb={2}
        pt={7}
        ref={ref}
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography
          variant="titleLg"
          color="var(--surface-on-color)"
          textTransform="capitalize"
          fontWeight={{ compact: 600, expanded: 700 }}
        >
          {label}
        </Typography>
        {data.length === 12 ? (
          <ButtonText
            size="small"
            label={`search ${
              device.size === "expanded" || device.size === "compact"
                ? ""
                : label
            }`}
            IconStart={SearchIcon}
            href={`/search?type=${entity}&sort=${sort}${
              task === "all" ? "" : `&task=${task}`
            }`}
            sx={{ color: "var(--secondary-color)", textWrap: "balance" }}
          />
        ) : null}
      </Stack>
      <Collapse collapsedSize={collapsedSize} in={showMore}>
        <Masonry
          key={device.size}
          columnGutter={8}
          maxColumnCount={2}
          items={data}
          columnCount={columnCount}
          overscanBy={data.length}
          onRender={collapsedSize === 0 ? measureCollapseSize : undefined}
          render={({ data: entity, index }) => (
            <Box
              sx={{
                visibility:
                  previewSize <= index && showMore === false
                    ? "hidden"
                    : undefined
              }}
            >
              {entity.__typename === "paper" ? (
                <CardProductSize2 tags paper={entity} />
              ) : entity.models.length ? (
                <ModelCardSize3 tags model={entity.models[0]} />
              ) : entity.label === "trending papers" ? (
                <PaperCardSize3 paper={entity} />
              ) : (
                <CardExternalCodeSize2 content={{ node: entity }} />
              )}
            </Box>
          )}
        />
      </Collapse>
      {previewSize < data.length ? (
        <Stack direction="row" alignItems="center" pt={3}>
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
          <ButtonOutlined
            label={`Show ${showMore ? "less" : "more"}`}
            IconEnd={showMore ? ShowLessIcon : ShowMoreIcon}
            onClick={() =>
              setShowMore(showMore => {
                const showingMore = !showMore;

                if (showingMore) {
                  setTimeout(() => {
                    ref.current?.scrollIntoView({
                      block: "start",
                      behavior: "smooth"
                    });
                  }, 1e3);
                }

                return showingMore;
              })
            }
            sx={{
              borderColor: "rgba(0,0,0,.1)",
              color: "var(--secondary-color)",
              transition: "all 225ms ease",
              "&:hover": {
                bgcolor: "var(--secondary-container)",
                color: "var(--secondary-on-container)"
              }
            }}
          />
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
        </Stack>
      ) : null}
    </>
  ) : null;
});

const TuneYourFeed = observer(function TuneYourFeed() {
  const { device, menu, user } = useStore();
  const signUp = useSignInDialog("Tune your feed");

  return (
    <ChipFilter
      StartIcon={TuneIcon}
      small={device.isPhone}
      label="Tune your feed"
      onClick={
        user.isAnonymous
          ? signUp
          : event =>
              menu.configure({
                anchor: event.target,
                Component: TaskFollow,
                sx: {
                  paper: { maxHeight: "70vh !important" },
                  menuList: { pt: 0, maxHeight: "100%" }
                }
              })
      }
      sx={{
        mr: 0.5,
        bgcolor: "var(--primary-color)",
        "& p, svg": {
          color: "var(--primary-on-color)"
        }
      }}
    />
  );
});

const TaskFollow = observer(function TaskFollow() {
  const { stars, snackbar, tasks } = useStore();
  const tasksFollowed = stars.tasks.map(task => task.name);

  return (
    <MenuTask
      key={stars.tasks.length}
      value={tasksFollowed}
      onChange={(_, values) => {
        const set = new Set(tasksFollowed);
        const newState = new Set(values.map(([task]) => task));

        for (const task of set) {
          if (newState.has(task) === false) {
            const entity = tasks.map.get(task);

            stars.quickSave({ save: false, entity });
            snackbar.notify({
              line1: "Stopped following:",
              line2: task,
              actions: [
                {
                  label: "Undo",
                  onClick() {
                    snackbar.set.open(false);
                    stars.quickSave({ save: true, entity });
                  }
                }
              ]
            });
          }
        }

        for (const task of newState) {
          if (set.has(task) === false) {
            const entity = tasks.map.get(task);

            stars.quickSave({ save: true, entity });
            snackbar.notify({
              line1: "Following:",
              line2: task,
              actions: [
                {
                  label: "Undo",
                  onClick() {
                    snackbar.set.open(false);
                    stars.quickSave({ save: false, entity });
                  }
                }
              ]
            });
          }
        }
      }}
    />
  );
});
