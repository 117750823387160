import { reaction } from "mobx";
import { fetchFromApiServer, fetchFromApiServerRaw } from "../../service/graph";
import { firestore } from "../../service/firebase";

export default class Agent {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    reaction(
      () => this._.user.loaded,
      loaded => {
        if (loaded === false) {
          this.reset();
        }
      }
    );

    reaction(
      () => this.threadId,
      threadId => {
        if (threadId && this.threads[0]?.history.length) {
          this.thread.refresh(threadId);
        }

        this.set.history(
          this.threads.find(thread => thread.id === threadId)?.history
        );
      }
    );

    reaction(
      () => this._.user.profile.threads,
      async savedThreads => {
        if (savedThreads?.length) {
          const threads = await Promise.all(savedThreads.map(this.thread.get));

          this.set.threads(threads.sort((a, b) => b.modified - a.modified));

          if (threads.some(thread => this.threadId === thread.id) === false) {
            this.set.threadId();
          }
          if (this.loaded === false) {
            this.set.loaded(true);
          }
        }
      }
    );

    reaction(
      () => [
        this._.user.loaded,
        this._.reader.publisher,
        this._.reader.paperID
      ],
      async ([loaded, publisher, paperID]) => {
        this.set.paperHasBeenRead(false);

        if (loaded && publisher && paperID) {
          const results = await fetchFromApiServer({
            path: `question/availability/${publisher}/${paperID}`
          });

          this.set.paperHasBeenRead(results?.available ?? false);
        }
      }
    );
  }
  set = {
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    // if out of credits
    disable: (disable = false) => {
      this.disable = disable;
    },
    history: (history = []) => {
      this.history = history;
    },
    threads: (threads = []) => {
      this.threads = threads;
    },
    threadId: threadId => {
      this.threadId = threadId;
    },
    // chat messages
    session: (session = []) => {
      this.session = session;
    },
    running: (running = false) => {
      this.running = running;
    },
    // docs
    paperHasBeenRead: (paperHasBeenRead = false) => {
      this.paperHasBeenRead = paperHasBeenRead;
    },
    // auto agent messages
    greeting: greeting => {
      this.greeting = greeting;

      if (greeting) {
        this.message.add(greeting, false);
      }
    },
    needsToSayHi: (needsToSayHi = true) => {
      this.needsToSayHi = needsToSayHi;
    }
  };
  chat = async userInput => {
    try {
      var input = userInput.trim();
      const threadId = this.threadId;
      const [vector] = await Promise.all([
        this._.search.vectorsSupported
          ? this._.search.vectorWorker.toVector(input)
          : undefined,
        this.message.add({ role: "user", content: input })
      ]);

      const assistantRandomId = Math.random();

      this.set.history([
        ...this.history,
        { id: assistantRandomId, role: "assistant", text: "" }
      ]);

      this.set.running(true);

      let history = [...this.history];
      const res = await fetchFromApiServerRaw({
        path: "agent",
        body: {
          vector,
          threadId,
          userInput: input,
          session: this.session,
          history: this.history
            .slice(-12, -2)
            .map(({ sources, ...message }) => message)
        }
      });

      const readableStream = res.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let streamError = false;

      readableStream.closed?.catch(() => {
        streamError = true;
      });

      const historyWithoutAssistantMessage = history.slice(0, -1);

      do {
        try {
          var { done, value } = await readableStream.read();

          if (done === false) {
            var {
              step,
              text,
              created,
              sources = [],
              citations = [],
              id = assistantRandomId
            } = JSON.parse(value.trim().split("\n").pop());

            var historyWithNewMessage = [
              ...historyWithoutAssistantMessage,
              {
                id,
                step,
                citations,
                sources,
                text,
                role: "assistant",
                created: new Date(created)
              }
            ];

            this.set.history(historyWithNewMessage);
          }
        } catch (error) {
          if (error.message.includes("JSON") === false) {
            console.error(error);
            console.log("error", { value });
          }
        }
      } while (streamError === false && done === false);
      // update the thread to have new history

      const threads = this.threads.filter(thread => thread.id !== threadId);
      const thread = this.threads.find(thread => thread.id === threadId);

      thread.history = historyWithNewMessage;

      this.set.threads([thread, ...threads]);
    } catch (error) {
      console.error(error);
    } finally {
      this.set.running(false);
      this._.analytics.track.usage.for("agent");
      this._.analytics.track.event("Agent", { input, output: text });
    }
  };
  thread = {
    create: async userInput => {
      const res = await fetch("/api/agent");
      const thread = await res.json();

      thread.modified = new Date(thread.modified);
      thread.created = new Date(thread.created);

      this.set.threads([thread, ...this.threads]);
      this.set.threadId(thread.id);

      if (userInput) {
        this.chat(userInput);
      }

      await this._.user.data.update({
        threads: firestore.arrayUnion(thread.id)
      });
    },
    get: async threadId => {
      const res = await fetch(`/api/agent?threadId=${threadId}`);
      const thread = await res.json();

      thread.created = new Date(thread.created);
      thread.modified = new Date(thread.modified);

      for (const message of thread.history) {
        message.created = new Date(message.created);
      }

      return thread;
    },
    refresh: async threadId => {
      const { history, modified } = await this.thread.get(threadId);
      const threads = [...this.threads];

      for (const thread of threads) {
        if (thread.id === threadId) {
          thread.modified = modified;
        }
      }

      this.set.history(history);
      this.set.threads(threads);
    },
    delete: async () => {
      this._.snackbar.notify({ text: "Deleting thread..." });

      await Promise.all([
        fetch(this.agentUrl, {
          method: "DELETE",
          body: JSON.stringify({ threadId: this.threadId })
        }),
        this._.user.data.update({
          threads: firestore.arrayRemove(this.threadId)
        })
      ]);

      this._.snackbar.notify({ text: "Thread deleted" });
    }
  };
  message = {
    add: async ({ role, content, metadata }) => {
      try {
        // if unique message, only add once
        if (metadata) {
          const [key, value] = metadata;
          let i = this.history.length;

          while (i--) {
            // do not write the same metadata message twice
            if (this.history[i].metaData?.[key] === value) {
              return; // already added this message
            }
          }
        }

        const threadId = this.threadId;
        const res = await fetch(this.agentUrl, {
          method: "post",
          body: JSON.stringify({ role, content, metadata })
        });

        if (res.ok) {
          const message = await res.json();

          message.created = new Date(message.created);

          if (threadId === this.threadId) {
            this.set.history([...this.history, message]);
          }

          const threads = [...this.threads];
          const thread = threads.find(thread => thread.id === threadId);

          thread.history = [...thread.history, message];

          this.set.threads(threads);
        }
      } catch (error) {
        console.error(error);
      }
    },
    delete: async (messageId, notify = true) => {
      try {
        this.set.history(
          this.history.filter(message => message.id !== messageId)
        );

        await fetch(this.agentUrl, {
          method: "DELETE",
          body: JSON.stringify({ messageId, threadId: this.threadId })
        });

        if (notify) {
          this._.snackbar.notify({ text: "Deleted" });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  get agentUrl() {
    return `/api/agent${this.threadId ? `?threadId=${this.threadId}` : ""}`;
  }
  get pages() {
    return new Set(this.session.map(({ page }) => page));
  }
  get maxThreads() {
    return this._.user.premium
      ? false
      : this.threads.length === (this._.user.isAnonymous ? 1 : 2);
  }
}
