import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Box, CardHeader, Collapse } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";

const Popup = dynamic(() => import("../../Popup"));
const Horizontal = dynamic(() => import("../../Chip/Horizontal"));
const SuggestionChip = dynamic(() => import("../../Chip/Suggestion"));
const TextField = dynamic(() => import("@mui/material/TextField"));
const Stack = dynamic(() => import("@mui/material/Stack"));
const CustomizeIcon = dynamic(() => import("@mui/icons-material/Settings"));
const ButtonFilled = dynamic(() => import("../../Button/Filled"));
const ToolTip = dynamic(() => import("../../Tooltip"));
const ButtonToggle = dynamic(() => import("../../Button/Toggle"));
const useSignInDialog = dynamic(() =>
  import("../../Dialog/dialogs/appWide/Login")
);
const useDialogUpgrade = dynamic(() =>
  import("../../Dialog/dialogs/appWide/Upgrade")
);

function Suggestions({ locked }) {
  const [entity, setEntity] = useState();
  const [quickPrompts, setQuickPrompts] = useState([]);
  const { analytics, agent, reader, user } = useStore();
  const disabled = agent.running || agent.loaded === false;

  useEffect(() => {
    if (agent.loaded) {
      let entity;
      let quickPrompts = [];
      const [viewing] = agent.session;

      switch (viewing?.page) {
        case "Model Page": {
          // const modelId = `${viewing.org}/${viewing.model}`;

          entity = "model";
          quickPrompts = user.profile.quickPrompts?.[entity] ?? [
            {
              title: "summarize model",
              prompt: `Give me a tldr of {modelId}`
            },
            {
              title: "use cases",
              prompt: `What are the use cases for {modelId}`
            },
            {
              title: "limitations",
              prompt: `What are limitations for {modelId}`
            }
            // Uncomment these if needed:
            // {
            //     title: "alternatives: higher performance",
            //     prompt: `Which models are trained or fine-tuned similarly to {model.id} but are higher performance`
            // },
            // {
            //     title: "alternatives: lower cost",
            //     prompt: `Which models are trained or fine-tuned similarly to {model.id} but are lower cost`
            // }
          ];
          break;
        }
        case "Research Paper": {
          if (agent.paperHasBeenRead && reader.paperNode) {
            // const { title } = reader.paperNode;

            entity = "paper";
            quickPrompts = user.profile.quickPrompts?.[entity] ?? [
              {
                title: "summary",
                prompt: `Summarize "{title}"`
              },
              {
                title: "insights",
                prompt: `What are the top new ideas / insights presented in the paper "{title}"`
              },
              {
                title: "applications",
                prompt: `For the paper "{title}", what are the practical applications where this can be immediately used? What are the real world limitations?`
              },
              {
                title: "limitations",
                prompt: `For the paper "{title}", what are the limitations presented? Help me understand numerically`
              },
              {
                title: "compare vs alternatives",
                prompt: `For the paper "{title}", how do techniques presented compare against alternatives? Help me understand the numerical diff`
              }
            ];
          }
          break;
        }
      }

      setEntity(entity);
      setQuickPrompts(quickPrompts);

      return () => {
        setQuickPrompts([]);
        setEntity();
      };
    }
  }, [
    user.profile.quickPrompts,
    agent.loaded,
    agent.session,
    agent.paperHasBeenRead,
    reader.paperNode
  ]);

  return (
    <Box overflow="hidden" position="relative" bgcolor="inherit" width="100%">
      <Horizontal
        chips={quickPrompts.map(({ title, prompt }, index) => (
          <Popup
            key={title}
            disable={disabled}
            Component={() => (
              <EditSuggestion
                entity={entity}
                index={index}
                title={title}
                prompt={prompt}
                quickPrompts={quickPrompts}
              />
            )}
          >
            <SuggestionChip
              label={title}
              disabled={disabled}
              onClick={() => {
                if (locked) {
                  return locked();
                }

                const entityObj =
                  entity === "paper" ? reader.paperNode : reader.model[0];
                const filledOutPrompt = prompt.replace(
                  /{(.*?)}/g,
                  (_, key) =>
                    key
                      .split(".")
                      .reduce((obj, prop) => obj?.[prop], entityObj) ||
                    `{${key}}`
                );

                agent.chat(filledOutPrompt);

                setQuickPrompts(
                  quickPrompts.filter(prompt => prompt.title !== title)
                );
                analytics.track.event("Agent Suggestion", { command: title });
              }}
            />
          </Popup>
        ))}
      />
    </Box>
  );
}

export default observer(Suggestions);

const EditSuggestion = observer(function EditSuggestion({
  entity,
  index,
  title,
  prompt,
  quickPrompts
}) {
  const [showMore, setShowMore] = useState(false);
  const [customTitle, setTitle] = useState(title);
  const [customPrompt, setPrompt] = useState(prompt);
  const { snackbar, user } = useStore();
  const signInDialog = useSignInDialog("Login to customize your prompts");
  const upgradeDialog = useDialogUpgrade(
    "Free members are limited to 1 customizable prompt"
  );

  return (
    <Stack
      spacing={3}
      p={2}
      bgcolor="var(--surface-container-lowest)"
      borderRadius="var(--shape-md-round)"
    >
      <CardHeader
        title={showMore ? "Customize quick prompt" : title}
        subheader={showMore ? undefined : "Prompt chips are customizable"}
        action={
          <ToolTip placement="left" title="Customize">
            <ButtonToggle
              selected={showMore}
              OffIcon={CustomizeIcon}
              OnIcon={CustomizeIcon}
              onClick={
                user.isAnonymous
                  ? signInDialog
                  : user.premium || user.profile.quickPrompts === undefined
                  ? () => setShowMore(showMore => !showMore)
                  : upgradeDialog
              }
            />
          </ToolTip>
        }
        slotProps={{
          title: { variant: "titleMd" },
          subheader: { variant: "bodyMd", color: "var(--secondary-color)" }
        }}
        sx={{ p: 0 }}
      />
      <Collapse mountOnEnter unmountOnExit in={showMore}>
        <Stack spacing={2}>
          <Stack spacing={2} alignItems="flex-end">
            <TextField
              fullWidth
              label="name"
              variant="standard"
              value={customTitle}
              onChange={event => setTitle(event.target.value)}
              sx={{
                typography: "bodyLg",
                "& .MuiInput-underline:before": {
                  borderBottomColor: "var(--outline-variant)" // Change the default color
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "var(--outline-color)" // Change color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var(--primary-color)" // Change color when focused
                }
              }}
            />
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <TextField
              multiline
              fullWidth
              label="prompt"
              variant="standard"
              value={customPrompt}
              onChange={event => setPrompt(event.target.value)}
              sx={{
                typography: "bodyLg",
                "& .MuiInput-underline:before": {
                  borderBottomColor: "var(--outline-variant)" // Change the default color
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "var(--outline-color)" // Change color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var(--primary-color)" // Change color when focused
                }
              }}
            />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <ButtonFilled
                disabled={
                  (prompt === customPrompt || !customPrompt.trim()) &&
                  (title === customTitle || !customTitle.trim())
                }
                label="Save"
                onClick={async () => {
                  quickPrompts[index] = {
                    title: customTitle.trim(),
                    prompt: customPrompt.trim()
                  };

                  user.data.update({
                    quickPrompts: {
                      ...user.profile.quickPrompts,
                      [entity]: quickPrompts
                    }
                  });

                  snackbar.notify({ text: "Prompt updated" });
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
});
