import { transaction } from "mobx";

export default class Menu {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
  }
  set = {
    anchor: anchor => {
      this.anchor = anchor;
    },
    anchorId: anchorId => {
      this.anchorId = anchorId;
    },
    Component: (Component = () => null) => {
      this.Component = Component;
    },
    open: (open = false) => {
      this.open = open;
    },
    onClose: (onClose = this.reset) => {
      this.onClose = onClose;
    },
    sx: (sx = {}) => {
      this.sx = sx;
    },
    options: (
      options = {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        }
      }
    ) => {
      this.options = options;
    },
    state: state => {
      this.state = state;
    }
  };
  close = this.set.open;
  // helpers
  configure = config => {
    transaction(() => {
      this.reset();

      for (const setting in config) {
        this.set[setting](config[setting]);
      }

      this.set.open(true);
    });
  };
}
