"use client";
import { useState } from "react";
import { useRouter } from "next/navigation";
import { Box, InputBase, Stack } from "@mui/material";
import { ArrowUpwardRounded as SendOnIcon } from "@mui/icons-material";

import { useStore } from "../../service/mobx";
import ButtonFilled from "../../component/Button/Filled";
import useDialogUpgrade from "../../component/Dialog/dialogs/appWide/Upgrade";
import useSignInDialog from "../../component/Dialog/dialogs/appWide/Login";

export default function AgentInput() {
  const [clicked, setClicked] = useState(false);
  const [userInput, setInput] = useState("");
  const { agent, user } = useStore();
  const signInDialog = useSignInDialog(
    "You're signed out. Sign in to create a new thread"
  );
  const upgradeDialog = useDialogUpgrade("Upgrade for more threads");
  const router = useRouter();

  return (
    <Box px={{ compact: 2, expanded: 3, large: 0 }}>
      <Stack
        position="sticky"
        bottom={0}
        useFlexGap
        spacing={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="var(--shape-md-round)"
        sx={theme => ({
          pl: 2,
          pr: 1,
          py: 1,
          border: 1,
          borderColor: "var(--outline-variant)",
          boxShadow: "var(--elevation-2)",
          transition: theme.transitions.create("all"),
          bgcolor: "var(--surface-container-lowest)",
          "&:hover": {
            bgcolor: "var(--surface-container-low)"
          }
        })}
      >
        <InputBase
          fullWidth
          value={userInput}
          multiline
          maxRows={8}
          minRows={4}
          placeholder="How can Bytez help you?"
          sx={{
            position: "relative",
            typography: "titleMd",
            color: "var(--surface-on-color)",
            ".Mui-disabled": {
              fontStyle: "italic",
              WebkitTextFillColor: "var(--secondary-on-container)"
            }
          }}
          inputProps={{
            sx: { color: "var(--surface-on-color)" }
          }}
          onChange={event => setInput(event.target.value)}
        />
        <Box alignSelf="flex-start">
          {userInput ? (
            <ButtonFilled
              disabled={clicked}
              sx={{ borderRadius: "var(--shape-md-round)" }}
              IconEnd={SendOnIcon}
              onClick={async () => {
                if (agent.maxThreads) {
                  if (user.isAnonymous) {
                    signInDialog();
                  } else if (user.premium === false) {
                    upgradeDialog();
                  }
                } else if (userInput.trim()) {
                  setClicked(true);

                  await agent.thread.create(userInput);

                  router.push("/agent");
                }
              }}
            />
          ) : null}
        </Box>
      </Stack>
    </Box>
    // <Stack direction="row" width="100%">
    //   <TextField
    //     fullWidth
    //     multiline
    //     minRows={4}
    //     variant="outlined"
    //     placeholder="How can Bytez help you?"
    //     onChange={event => setInput(event.target.value)}
    //     sx={{
    //       borderRadius: "var(--shape-md-round)",
    //       boxShadow: "var(--shadows-3)",
    //       "& fieldset": {
    //         border: 1,
    //         transition: "all 1s",
    //         borderColor: "var(--outline-variant)"
    //       },
    //       "& .MuiInputBase-input": {
    //         color: "var(--surface-on-color)"
    //       },
    //       "& .MuiInputBase-input::placeholder": {
    //         opacity: 1,
    //         typography: "titleMd",
    //         color: "var(--secondary-color)"
    //       }
    //     }}
    //     slotProps={{
    //       input: {
    //         sx: {
    //           outline: "none",
    //           typography: "bodyLg",
    //           fontWeight: 600,
    //           borderRadius: "var(--shape-md-round)",
    //           bgcolor: "var(--surface-container-lowest)"
    //         }
    //       }
    //     }}
    //   />
    //   {input ? <ButtonFilled IconEnd={SendOnIcon} /> : null}
    // </Stack>
  );
}
