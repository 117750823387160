import { Suspense } from "react";
import dynamic from "next/dynamic";
import { Stack } from "@mui/material";

import { entities } from "../../../component/Search/Hooks";

const FilterChips = dynamic(() =>
  import("../../../component/Search/Suggestions/Filter/Chips")
);
const FilterTime = dynamic(() => import("./Chip/Time"));
const FilterSort = dynamic(() => import("./Chip/Sort"));
const FilterTask = dynamic(() => import("./Chip/Task"));
const FilterPublisher = dynamic(() => import("./Chip/Publishers"));

export default function Filters({ query: { type } }) {
  const filters =
    {
      models: [FilterTask, FilterSort],
      papers: [FilterPublisher, FilterTask, FilterTime, FilterSort],
      videos: [FilterTask, FilterTime, FilterSort],
      tasks: [FilterTask, FilterSort]
    }[type] || [];

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      mx="-16px !important"
      px="16px !important"
      pb={0.25}
      sx={filterChipScrollSx}
    >
      <Stack useFlexGap direction="row" spacing={1}>
        {entities.map(({ Icon, label, href }) => (
          <Suspense key={label}>
            <FilterChips label={label} Icon={Icon} href={href} />
          </Suspense>
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {filters.map((Filter, index) => (
          <Suspense key={index}>
            <Filter />
          </Suspense>
        ))}
      </Stack>
    </Stack>
  );
}

export const filterChipScrollSx = {
  zIndex: 1,
  overflow: "auto",
  pr: { compact: 2, expanded: 5, large: 0 },
  // disable scroll bar
  "&::-webkit-scrollbar": {
    display: "none"
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */
};
