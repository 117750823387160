import { reaction } from "mobx";

import { firestore } from "../firebase";
import {
  subscribe,
  graphQuery,
  convertToID,
  fetchFromApiServer
} from "../graph";
import { menuOptionsPublisher } from "../../component/Menu/menus/Search/Publisher";

export default class Reader {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    if (typeof window === "undefined") {
      return;
    }
    // related content subscriptions
    // whenever the paper changes, store a mobile version of it
    let subscriptions = {};
    const graphSubscribe = (key, options, callback) => {
      subscriptions[key] ??= subscribe(
        {
          first: 1,
          query: options.query,
          variables: options.variables,
          pollInterval: 30e3
        },
        callback
      );
    };

    reaction(
      () => [this._.user.loaded, this.paperNode],
      ([userLoaded, paper]) => {
        if (userLoaded && paper) {
          const { publisher, paperID, title } = paper;
          const watch = (collection, path, callback) => {
            subscriptions[path] = firestore
              .doc(collection, path)
              .watch(callback);
          };
          const crawl = async ({ expiry, modified, action, topic }) => {
            let messageSent = // if we've never searched
              modified === undefined ||
              // or content is expired
              expiry < new Date();

            if (messageSent) {
              // console.log({ expiry, modified, action, topic });
              // this._.snackbar.message.add({ text: "✨ Crawling the web ✨" });
              //
              await this._.utilities.publishMessage({
                topic,
                action,
                entity: paperID + publisher,
                message: {
                  publisher,
                  paperID,
                  title,
                  paperNodeId: paper.id,
                  summary: paper.summary
                }
              });
            }

            return messageSent;
          };

          this.set.related({
            ...this.related,
            tasks: paper.tags,
            audio: [{ id: "tts" }],
            authors: paper.authorArray.map(name => ({ id: name, name })),
            datasets: paper.links
              .filter(
                ({ type, data }) =>
                  type === "dataset" && data.includes("Add Datasets") === false
              )
              .map(({ type, url, data, id }) => ({
                id,
                type,
                publisher: "paperswithcode.com",
                clean: { title: JSON.parse(data).name, url }
              }))
          });

          this.set.loaded(true);

          // videos
          watch(`content/${publisher}/videos/`, paperID, async doc => {
            const twoWeeks = 86400e3 * 14;
            const { content, modified } = doc.data() ?? {};
            const videos = await processYouTubeVideos({ content });
            // set related
            this.set.related({ ...this.related, videos });
            // check if expired
            crawl({
              expiry: new Date(Date.now() + twoWeeks),
              modified: modified?.toDate(),
              action: "refresh-videos",
              topic: "search_for_videos"
            });
          });
          // discussions, blogs
          watch("google-search", paperID, doc => {
            const { content = [], modified, staleAfter } = doc.data() ?? {};
            const [blogs, discussions] = contentToBlogsAndDiscussions(content);
            // set related
            this.set.related({ ...this.related, discussions, blogs });
            // check if expired
            crawl({
              modified: modified?.toDate(),
              expiry: staleAfter?.toDate(),
              action: "refresh-content",
              topic: "links_scrape"
            });
          });
          // graph subscriptions
          const variables = { id: convertToID({ publisher, paperID }) };
          let notCrawled = true;
          // code
          graphSubscribe(
            "repos",
            { variables, query: this._.gql.get("repos") },
            async result => {
              const code =
                result?.data?.papers?.[0]?.reposConnection?.edges ?? [];
              // set related
              this.set.related({ ...this.related, code });
              // refresh github + hf repos after 1 week
              const modified = new Date(this.paperNode.lastCheckedForCode);
              const expiry = new Date(
                Math.ceil(modified.getTime() + 86400e3 * 7)
              );

              if (notCrawled) {
                notCrawled = false;

                const [crawlingGithub, crawlingHF] = await Promise.all([
                  crawl({
                    expiry,
                    modified,
                    action: "refresh-github",
                    topic: "github_arxiv"
                  }),
                  crawl({
                    expiry,
                    modified,
                    action: "refresh-huggingface",
                    topic: "huggingface_paper_link"
                  })
                ]);

                if ((crawlingGithub && crawlingHF) === false) {
                  subscriptions.repos();
                }
              }
            }
          );
          // similar papers
          if (publisher === "arxiv") {
            graphQuery({
              variables,
              query: this._.gql.get("similar")
            }).then(result => {
              const similar = deDupe(result?.data?.top_similar_papers ?? []);
              // set related
              this.set.related({ ...this.related, similar });
            });
          } else {
            const year = new Date(paper.updated).getFullYear();
            // if at conference, load similar at conference instead
            fetchFromApiServer({
              path: `conference/${publisher}/${paperID}?year=${year}&limit=20`
            }).then((result = []) => {
              for (const paper of result) {
                paper.scoreSimilarity = paper.similarityScore / 100;
              }

              this.set.related({
                ...this.related,
                [`${menuOptionsPublisher[publisher]} ${year}`]: deDupe(result)
              });
            });
          }

          // incoming citations
          graphQuery({
            variables,
            query: this._.gql.get("citations")
          }).then(result => {
            const citations = result?.data?.papers?.[0]?.citation ?? [];
            // set related
            this.set.related({ ...this.related, citations });
          });
          // incoming citations
          graphQuery({
            variables,
            query: this._.gql.get("alsoAuthoredBy")
          }).then(result => {
            this.set.related({
              ...this.related,
              "same authors": result?.data?.also_by_authors ?? []
            });
          });
          // console.log("down here loading refs");
          // outgoing references
          graphSubscribe(
            "references",
            { variables, query: this._.gql.get("references") },
            result => {
              const references =
                result?.data?.papers?.[0]?.referencesConnection?.edges ?? [];
              const isProcessedIndex =
                (references[0]?.index ?? 0) !== 0 &&
                references.some(reference => reference.index === null) ===
                  false;
              // console.log({ references });
              if (isProcessedIndex) {
                subscriptions.references();
              }
              // set related
              this.set.related({
                ...this.related,
                references: references.map(({ index, node }) => ({
                  ...node,
                  id: node.id + index,
                  index,
                  url: node.url || `https://google.com/search?q=${node.title}`,
                  title: isProcessedIndex
                    ? `[${index}] ${node.title}`
                    : node.title
                }))
              });
            }
          );
          // datasets
          firestore
            .doc(`content/${publisher}/pwc/`, paperID)
            .get()
            .then(async doc => {
              const twoWeeks = 86400e3 * 14;
              const { url, modified } = doc.data() ?? {};
              // if no URL or expired, crawl
              crawl({
                expiry:
                  url === undefined
                    ? new Date(0)
                    : new Date(Date.now() + twoWeeks),
                modified: modified?.toDate(),
                action: "crawl-pwc",
                topic: "pwc_scrape"
              });
            });
        } else {
          Object.values(subscriptions).forEach(unsubscribe => unsubscribe());
          subscriptions = {};
          this.set.loaded(false);
        }
      }
    );
  }
  set = {
    publisher: (publisher = "") => {
      this.publisher = publisher;
    },
    paperID: (paperID = "") => {
      this.paperID = paperID;
    },
    paperNode: paperNode => {
      this.paperNode = paperNode;
    },
    article: article => {
      this.article = article;
    },
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    error: error => {
      this.error = error;
    },
    related: (related = {}) => {
      this.related = related;
    },
    videoPlaying: videoPlaying => {
      this.videoPlaying = videoPlaying;
    },
    setExpanded: (setExpanded = () => null) => {
      this.setExpanded = setExpanded;
    },
    fullScreen: (fullScreen = false) => {
      this.fullScreen = fullScreen;
    },
    config: config => {
      this.config = config;
    },
    tts: (tts = {}) => {
      this.tts = tts;
    },
    tab: tab => {
      this.tab = tab;
    },
    model: model => {
      this.model = model;
    }
  };
  scrollTo = (selector, select) => {
    const element = document.querySelector(selector);
    const header = element
      ?.closest(".MuiAccordion-root")
      ?.querySelector(
        ".MuiAccordionSummary-root .MuiTypography-root"
      )?.textContent;

    if (header) {
      this.setExpanded(expanded => {
        const isAlreadyExpanded = expanded[header];

        setTimeout(
          () => {
            if (select) {
              const selection = document.getSelection();
              const range = document.createRange();

              range.selectNodeContents(element);
              selection.addRange(range);

              const deselectOnClick = () => {
                document.getSelection().removeAllRanges();
                document.removeEventListener("click", deselectOnClick);
              };

              document.addEventListener("click", deselectOnClick);
            }

            element.scrollIntoView({
              behavior: isAlreadyExpanded ? undefined : "smooth",
              block: "center"
            });
          },
          isAlreadyExpanded ? 0 : 500
        );

        return isAlreadyExpanded ? expanded : { ...expanded, [header]: true };
      });
    }
  };
  // computed
  get sections() {
    return (
      this.article?.sections?.filter(section => section.heading !== "Info") ??
      []
    );
  }
  get sectionLastIndex() {
    return this.sections.length - 1;
  }
  get available() {
    return this.paperNode !== undefined && this.article !== undefined;
  }
  get contentToCard() {
    return {
      conference: ["paper", "paper"],
      authors: ["user", "paper"],
      code: ["code", "paper"],
      audio: ["audio", "paper"],
      tasks: ["task", "paper"],
      videos: ["video", "paper"],
      datasets: ["external", "paper"],
      discussions: ["external", "paper"],
      blogs: ["external", "paper"],
      similar: ["paper", "paper"],
      references: ["paper", "paper"],
      citations: ["paper", "paper"],
      "same authors": ["paper", "paper"],
      "same creator": ["model", "model"],
      "identical name": ["model", "model"],
      "shared architecture": ["model", "model"]
    };
  }
  get relatedTabs() {
    return this.relatedContentFiltered.map(([tab]) => [tab, tab.toLowerCase()]);
  }
  get relatedContent() {
    const relatedContent = [];

    for (const tab in this.contentToCard) {
      if (tab === "conference") {
        var content = [];

        for (const related in this.related) {
          if (related.toLowerCase().startsWith(this.paperNode?.publisher)) {
            var label = related;

            content = this.related[related];
            break;
          }
        }
      } else {
        content = this.related[tab] ?? [];
        label =
          content.length === 1 && tab.endsWith("s") ? tab.slice(0, -1) : tab;
      }

      if (content.length !== 0) {
        relatedContent.push([
          // tab to display in html: example: authors or author
          label,
          // content array
          content,
          // card to render
          this.contentToCard[tab][0],
          // url
          tab
        ]);
      }
    }

    return relatedContent;
  }
  get relatedContentFiltered() {
    const relatedContentFiltered = this.relatedContent.filter(
      ([, , , tab]) =>
        this._.device.isDesktop === false ||
        this.viewing === this.contentToCard[tab]?.[1] ||
        this.supportedPublisher.has(tab.split(" ")[0])
    );

    return this.config === undefined
      ? relatedContentFiltered
      : relatedContentFiltered.filter(
          ([title]) =>
            this.config.related.has(title) ||
            (this.config.related.has("conference") && title?.includes(" 202"))
        );
  }
  get whiteLabelled() {
    return this.config !== undefined;
  }
  get maxTTSIndex() {
    return this.sections.findIndex(({ heading }) =>
      heading.toLowerCase().includes("reference")
    );
  }
  get supportedPublisher() {
    return new Set(["arxiv", "neurips", "cvpr", "mlsys"]);
  }
  get referencesMap() {
    const references = this.related.references ?? [];
    let i = references.length;
    const map = {};

    while (i--) {
      map[references[i].index] = references[i];
    }

    return map;
  }
  get tldr() {
    return this._.reader.paperNode?.summaries?.[0]?.summary;
  }
  get viewing() {
    return this.tab === "paper" || this.tab === "model"
      ? this.tab
      : this.contentToCard[this.tab]?.[1];
  }
}

// related content
export async function processYouTubeVideos({
  content = [],
  endpoint = youTubeIds =>
    fetchFromApiServer({ path: "youtube", body: { youTubeIds } })
}) {
  const videos = [];
  const videoIdToTitle = new Map();
  const youTubeBatches = [];
  let batchOfVideoIds = [];

  for (
    let item,
      youtube = content.filter(({ url }) => url.includes("youtube.com")),
      videoId,
      i = 0,
      j = youtube.length;
    i < j;
    i++
  ) {
    item = youtube[i];
    videoId =
      item.raw?.pagemap?.videoobject?.[0]?.videoid ||
      new URLSearchParams(new URL(item.url).search).get("v");

    if (videoId && videoIdToTitle.has(videoId) === false) {
      // capture this videoID in a list of 50
      if (batchOfVideoIds.length === 50) {
        youTubeBatches.push(batchOfVideoIds);
        batchOfVideoIds = [];
      }
      //
      videoIdToTitle.set(videoId, item.clean?.title);
      batchOfVideoIds.push(videoId);
    }
  }
  // add the last batch to the lookup
  if (batchOfVideoIds.length !== 0) {
    youTubeBatches.push(batchOfVideoIds);
  }
  // hit the youtube api
  for (
    let toIdString = (videoIds, videoId) => (videoIds += videoId + ","),
      iso8601Regex =
        /PT(?:(?<day>\d{0,})D)?(?:(?<hour>\d{0,})H)?(?:(?<min>\d{0,})M)?(?:(?<sec>\d{0,})S)?/,
      i = 0,
      j = youTubeBatches.length;
    i < j;
    i++
  ) {
    const results = await endpoint(
      youTubeBatches[i].reduce(toIdString, "").slice(0, -1)
    );
    const items = results?.items ?? [];

    for (let k = 0, j = items.length; k < j; k++) {
      const video = items[k];

      if (video.status.embeddable) {
        const {
          contentDetails: { duration },
          snippet: { channelTitle, thumbnails },
          statistics: {
            likeCount = "0",
            dislikeCount = "0",
            favoriteCount = "0",
            viewCount = "0",
            commentCount = "0"
          }
        } = video;
        const {
          day = "",
          hour = "",
          min = "",
          sec = ""
        } = iso8601Regex.exec(duration)?.groups ?? {};

        videos.push({
          thumbnails,
          id: video.id,
          publisher: "youtube.com",
          url: `https://youtube.com/watch?v=${video.id}`,
          viewCount,
          channelTitle,
          title: videoIdToTitle.get(video.id),
          duration:
            Number(sec) +
            Number(min) * 60 +
            Number(hour) * 3.6e3 +
            Number(day) * 8.64e4,
          score:
            0.5 * Number(viewCount) +
            Number(likeCount) -
            Number(dislikeCount) +
            2 * Number(favoriteCount) +
            Number(commentCount)
        });
      }
    }
  }
  const presentations = content
    .filter(({ url }) => url.includes("slideslive"))
    .map(video => ({ ...video, publisher: "slideslive.com" }));
  const finalVideos = new Map();
  let lastIndex = 0;

  do {
    var youtube = videos.shift();
    var presentation = presentations.shift();
    const i = finalVideos.has(Math.max(0, finalVideos.size - 1))
      ? finalVideos.size
      : Math.max(0, finalVideos.size - 1);

    if (youtube) {
      finalVideos.set(i, youtube);
    }
    if (presentation) {
      lastIndex = i === 0 ? 1 : lastIndex * 2;
      finalVideos.set(lastIndex++, presentation);
    }
  } while (youtube || presentation);

  return [...finalVideos.entries()].sort((a, b) => a[0] - b[0]).map(a => a[1]);
}

export function contentToBlogsAndDiscussions(firebaseArray = []) {
  function dedupeContent(content) {
    const items = {};

    for (let item, i = 0, j = content.length; i < j; i++) {
      item = content[i];
      items[item.title] = item;
    }

    return Object.values(items);
  }

  const blogs = {};
  const discussions = {};

  for (
    let content = dedupeContent(firebaseArray),
      item,
      type = {
        "stackexchange.com": "discussion",
        "github.io": "blog",
        "medium.com": "blog",
        "towardsdatascience.com": "blog",
        "distill.pub": "blog",
        "openreview.net": "discussion",
        "reddit.com": "discussion",
        "fast.ai": "discussion",
        "pytorch.org": "discussion",
        "kaggle.com": "discussion"
      },
      i = 0,
      j = content.length;
    i < j;
    i++
  ) {
    item = content[i];
    const url = new URL(item.link);
    item.id = url.href;
    item.url = item.link;
    item.publisher = url.hostname.split(".").slice(-2).join(".").toLowerCase();
    item.domain = url.hostname.startsWith("www.")
      ? item.publisher
      : url.hostname;
    item.type = type[item.publisher];
    item.img =
      item.thumbnail ?? item.pagemap?.cse_thumbnail?.slice()[0]?.src ?? null;

    if (item.type === "blog") {
      blogs[item.id] = item;
    } else if (item.type === "discussion") {
      discussions[item.id] = item;
    } else if (item.type === undefined && item.url) {
      //if somehow missing but has an ID, lump in with Blogs
      blogs[item.id] = item;
    } else {
      console.warn("unknown publisher", item);
    }
  }
  return [Object.values(blogs), Object.values(discussions)];
}

// todo, get API server to do this
export function deDupe(results = []) {
  const uniqueNodes = {};

  for (let result, i = 0, j = results.length; i < j; i++) {
    result = results[i];

    if (uniqueNodes[result.id] === undefined) {
      uniqueNodes[result.id] = result;
    }
  }

  return Object.values(uniqueNodes);
}
