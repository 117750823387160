"use client";
import { Fragment, useState } from "react";
import Image from "next/image";
import { Stack, CardHeader, Typography, Box, Grid } from "@mui/material";

import { useStore } from "../../service/mobx";
// import Star from "../Star";
import FilledCard from "../Card/Filled";
import { textRank } from "../../service/mobx/utilities";
// import { ModelCardSize1 } from "./Model";
import GameBadge from "../GameBadge";
import { menuOptionsPublisher } from "../Menu/menus/Search/Publisher";
import { Tasks } from "./Paper";
import RenderPaperTitle from "../Markdown/PaperTitle";

function CardProductSize2({ paper, tags = false }) {
  const [imgTaskFailed, setTaskFailed] = useState(paper.imgTask === undefined);
  const [imgPaperFailed, setPaperFailed] = useState(
    paper.imgPaper === undefined
  );

  return (
    <Card paper={paper}>
      <Grid
        container
        columns={10}
        sx={{ "&:hover .overlay": { bgcolor: "unset" } }}
        flexDirection={{ compact: "column", expanded: "row" }}
      >
        {imgTaskFailed && imgPaperFailed ? null : (
          <Grid item compact={12}>
            <Box
              m={-2}
              mb={2}
              overflow="hidden"
              position="relative"
              height={160}
              borderRadius="var(--shape-md-top)"
              sx={{
                "& img": {
                  width: "100%",
                  display: "block",
                  objectFit: "cover",
                  objectPosition: "top center"
                }
              }}
            >
              <Image
                fill
                alt={paper.title}
                src={imgPaperFailed ? paper.imgTask : paper.imgPaper}
                sizes="(max-width: 600px) 320px, 480px"
                onError={() => {
                  if (!imgTaskFailed) {
                    setTaskFailed(true);
                  } else {
                    setPaperFailed(true);
                  }
                }}
              />
              <Box
                className="overlay"
                sx={theme => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  mixBlendMode: "multiply",
                  transition: "var(--transition-all)",
                  bgcolor: theme.alpha("var(--surface-container-color)", 0.3)
                })}
              />
            </Box>
          </Grid>
        )}
        <Grid item compact={12} medium>
          <Header org publisher models size="small" product={paper} />
          <Models paper={paper} />
          {tags ? (
            <Tasks
              small
              paddingLeft={!!paper.scoreTrending || !!paper.scoreSota}
              tags={paper.tags}
            />
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}

function CardProductSize3({ paper, tags = false }) {
  const [imgTaskFailed, setTaskFailed] = useState(paper.imgTask === undefined);
  const [imgPaperFailed, setPaperFailed] = useState(
    paper.imgPaper === undefined
  );

  return (
    <Card paper={paper}>
      <Grid
        container
        columns={10}
        sx={{ "&:hover .overlay": { bgcolor: "unset" } }}
        flexDirection={{ compact: "column", expanded: "row" }}
      >
        {imgTaskFailed && imgPaperFailed ? null : (
          <Grid item compact={12}>
            <Box
              m={-2}
              mb={2}
              overflow="hidden"
              position="relative"
              height={160}
              borderRadius="var(--shape-md-top)"
              sx={{
                "& img": {
                  width: "100%",
                  display: "block",
                  objectFit: "cover",
                  objectPosition: "top center"
                }
              }}
            >
              <Image
                fill
                alt={paper.title}
                src={imgPaperFailed ? paper.imgTask : paper.imgPaper}
                sizes="(max-width: 600px) 320px, 480px"
                onError={() => {
                  if (!imgTaskFailed) {
                    setTaskFailed(true);
                  } else {
                    setPaperFailed(true);
                  }
                }}
              />
              <Box
                className="overlay"
                sx={theme => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  mixBlendMode: "multiply",
                  transition: "var(--transition-all)",
                  bgcolor: theme.alpha("var(--surface-container-color)", 0.3)
                })}
              />
            </Box>
          </Grid>
        )}
        <Grid item compact={12} medium>
          <Header org publisher models size="small" product={paper} />
          <Models paper={paper} />
          {tags ? (
            <Tasks
              small
              paddingLeft={!!paper.scoreTrending || !!paper.scoreSota}
              tags={paper.tags}
            />
          ) : null}
        </Grid>
      </Grid>
      <Box pt={2}>
        <Summary paper={paper} />
      </Box>
    </Card>
  );
}

function CardProductSize4({ paper }) {
  return (
    <Card paper={paper}>
      <Header org authors publisher product={paper} />
      <Summary paper={paper} />
      <Models paper={paper} />
    </Card>
  );
}

export { CardProductSize2, CardProductSize3, CardProductSize4 };

function Card({ paper, children, ...props }) {
  const { analytics } = useStore();
  const { publisher, paperID } = paper;

  return (
    <FilledCard
      prefetch
      tone="var(--surface-container-lowest)"
      href={`docs/${publisher}/${paperID}/paper`}
      onClick={() => {
        analytics.track.event("Paper Click", { publisher, paperID });
      }}
      {...props}
    >
      {children}
    </FilledCard>
  );
}

const Models = () => null;
// (() => null) ||
// (({ paper }) =>
//   paper.models?.length ? (
//     <Stack
//       spacing={1}
//       direction="row"
//       flexWrap="wrap"
//       pl={paper.status ? 7 : undefined}
//       sx={{
//         "& > *": {
//           mt: 1
//         }
//       }}
//     >
//       {paper.models.map(model => (
//         <ModelCardSize1 key={model.id} model={model} />
//       ))}
//     </Stack>
//   ) : undefined);

function SubHeader({
  product,
  task = false,
  org = false,
  params = false,
  authors = false,
  publisher = false,
  models = false
}) {
  const { utilities } = useStore();

  try {
    const authorArray =
      product.authorArray ?? JSON.parse(product?.authors ?? "[]");
    const remainingAuthorsLength = authorArray.slice(1).length;
    var authorString = `${authorArray.slice(0, 1).join(", ")}${
      remainingAuthorsLength ? " + " + remainingAuthorsLength : ""
    }`;
  } catch {
    //
  }

  const items = [
    utilities.paperTime(product.published || product.repo?.created),
    org ? product.org : undefined,
    authors ? authorString : undefined,
    publisher ? menuOptionsPublisher[product.publisher] : undefined,
    models && product.reposAggregate?.count
      ? `${product.reposAggregate.count.toLocaleString()} model repo${
          product.reposAggregate.count === 1 ? "" : "s"
        }`
      : undefined,
    task && product.task ? `${product.task}` : undefined,
    params && product.params
      ? `${utilities.formatNumber(product.params)} params`
      : undefined
  ].filter(defined => defined);

  return (
    <Stack
      useFlexGap
      py={0.5}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
    >
      {items.map((content, index, array) => (
        <Fragment key={index}>
          <Box component="span" color="var(--secondary-color)">
            {content}
          </Box>
          {index + 1 === array.length ? undefined : <Box mx={0.5}>·</Box>}
        </Fragment>
      ))}
    </Stack>
  );
}

export const Header = ({
  product,
  size = "medium",
  noStar,
  ...subheaderProps
}) => (
  <CardHeader
    avatar={
      Number.isFinite(product?.scoreSota) ||
      Number.isFinite(product?.scoreTrending) ||
      Number.isFinite(product?.scoreSimilarity) ? (
        <GameBadge
          scoreTrending={product.scoreTrending}
          scoreSimilarity={product.scoreSimilarity}
          scoreSota={product.scoreSota}
          models={product.models}
        />
      ) : undefined
    }
    title={
      product?.displayName ? (
        <>
          <Box component="span" color="var(--secondary-color)" fontWeight={300}>
            {`${product.org} `}
          </Box>
          {product.displayName}
        </>
      ) : (
        <RenderPaperTitle paperTitle={product.title} />
      )
    }
    titleTypographyProps={{
      variant: "headlineMd",
      color: "var(--surface-on-color)",
      sx: theme => ({ textWrap: "balance", ...theme.typography.lineClamp(3) }),
      typography: {
        compact: size === "small" ? "labelMd" : "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "titleLg"
            : "titleSm",
        large:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleMd"
            : size === "xl"
            ? "headlineMd"
            : "titleMd"
      }
    }}
    subheader={<SubHeader product={product} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      color: "var(--secondary-color)",
      typography: {
        compact: "bodySm",
        expanded:
          size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
      }
    }}
    // action={
    //   noStar ? undefined : (
    //     <Box px={1}>
    //       <Star size={size} entity={product} />
    //     </Box>
    //   )
    // }
    sx={{
      p: 0,
      alignItems: "flex-start",
      height: size === "xl" ? 152 : undefined
    }}
  />
);

function Summary({ paper, size, sentences = 2 }) {
  const mlSummary = paper?.summaries?.[0]?.summary;
  const summary = textRank(mlSummary ?? paper.summary, sentences);

  return summary ? (
    <Typography
      component="p"
      color="var(--surface-on-color)"
      variant="bodyLg"
      sx={theme => theme.typography.lineClamp(size === 3 ? 2 : 7)}
    >
      {summary}
    </Typography>
  ) : null;
}
