import DeleteIcon from "@mui-symbols-material/w400/DeleteRounded";

import { useStore, observer } from "../../../service/mobx";
import { ListItem } from "../../List/ListItem";

function SessionMenu() {
  // const { snackbar, user } = useStore();

  return <Component />;
}
const Component = observer(function Component() {
  const { agent, menu, user } = useStore();

  return (
    <ListItem
      line1Sx={{ textTransform: "unset" }}
      line1="Delete"
      line2={user.isAnonymous ? "Login to reveal" : undefined}
      leading={{ Icon: DeleteIcon }}
      sx={{
        transition: "all 200ms",
        "& svg, & span": {
          color: "var(--tertiary-color)"
        },
        "&:hover": {
          bgcolor: "var(--tertiary-container)",
          "& svg, span": {
            color: "var(--tertiary-on-container)"
          }
        }
      }}
      onClick={() => {
        menu.close();
        agent.thread.delete();
      }}
    />
  );
});

export default observer(SessionMenu);
