"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { Box } from "@mui/material";
import { autorun } from "mobx";

import { useStore, observer } from "../../service/mobx";
import { getTldrMd } from ".";
import Input from "./Input";
import Messages from "./Messages";
import Suggestions from "./Input/Suggestions";
import useDialogSignIn from "../Dialog/dialogs/appWide/Login";
import useDialogUpgrade from "../Dialog/dialogs/appWide/Upgrade";

function HooksClient({ trendingMd, tldrMd }) {
  const { agent, reader, user } = useStore();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname === "/") {
      const session = [...agent.session];
      const page = "Discover";
      const firstView = agent.pages.has(page) === false;

      session.push({ page });
      agent.set.session(session);

      if (firstView) {
        return greetTrending(agent, trendingMd);
      }
    } else if (reader.paperID && reader.publisher && reader.paperNode?.title) {
      const session = [...agent.session];
      const page = "Research Paper";
      const firstView = agent.pages.has(page) === false;

      session.push({
        page,
        paperID: reader.paperID,
        publisher: reader.publisher,
        title: reader.paperNode?.title
      });
      agent.set.session(session);

      if (firstView) {
        return greetPaper(agent, tldrMd, reader.publisher, reader.paperID);
      }
    } else if (pathname.startsWith("/docs") && reader.article === undefined) {
      const session = [...agent.session];
      const [, , org, model] = pathname.split("/");

      session.push({ page: "Model Page", org, model });
      agent.set.session(session);
    }
  }, [agent, reader, pathname, reader.paperNode?.title, trendingMd, tldrMd]);

  useEffect(() => {
    if (agent.loaded && agent.needsToSayHi) {
      const welcome = {
        id: "welcome",
        role: "assistant",
        text: user.firstExperience
          ? `**Welcome to Bytez.**\n\nI'm an AI agent that's grounded in research.\n\nEvery day, I read hundreds of newly published AI papers. You can ask me about these papers.\n\nI can help you search, explain concepts, and answer your questions about AI models and research.\n\nWhat would you like to learn today?`
          : `Welcome back ${
              user.isAnonymous ? "" : user.name?.split(" ")?.[0] ?? ""
            }`
      };

      agent.set.needsToSayHi(false);
      agent.message.add(welcome, false);
    }
  }, [agent, user, agent.loaded, agent.needsToSayHi]);
}

export default observer(HooksClient);

function greetTrending(agent, trendingMd) {
  if (trendingMd) {
    const { markdown, papers } = trendingMd;

    agent.set.greeting({
      id: "trending",
      role: "assistant",
      text: markdown,
      sources: papers,
      created: Date.now()
    });

    return agent.set.greeting;
  }
}
function greetPaper(agent, tldrMd, publisher, paperID) {
  if (tldrMd) {
    agent.set.greeting({
      id: "tldr",
      role: "assistant",
      text: tldrMd,
      created: Date.now()
    });

    return agent.set.greeting;
  } else {
    const cleanUp = autorun(async () => {
      if (agent.paperHasBeenRead) {
        tldrMd = await getTldrMd(publisher, paperID);

        if (tldrMd) {
          greetPaper(agent, tldrMd);
        }
      }
    });

    return () => {
      cleanUp();
      agent.set.greeting();
    };
  }
}

export const MobileAgent = observer(function MobileAgent() {
  const locked = useFeatureGate();

  return (
    <>
      <Messages mobile />
      <Box
        pb={1}
        px={2}
        position="fixed"
        left={0}
        right={0}
        bottom={0}
        borderTop={1}
        bgcolor="var(--surface-bright)"
        borderColor="var(--outline-variant)"
      >
        <Suggestions mobile locked={locked} />
        <Input mobile locked={locked} />
      </Box>
    </>
  );
});

export function useFeatureGate() {
  const [cacheRead, setCacheRead] = useState(false);
  const { analytics, agent, user, utilities } = useStore();
  const signInDialog = useDialogSignIn(
    "Research Assistant is free for the community"
  );
  const upgradeDialog = useDialogUpgrade();
  const usageAllowed = user.premium
    ? Infinity
    : user.isAnonymous
    ? user.profile.cohort === "low"
      ? 0
      : 5
    : user.profile.cohort === "low"
    ? 10
    : 30;
  const usage = user.isAnonymous
    ? user.profile.featureGate?.agent ?? 0
    : analytics.meters.usage?.agent ?? 0;
  const isGated = usageAllowed <= usage;

  // console.log({ name: "agent", usage, usageAllowed });

  // sync between device profiles
  useEffect(() => {
    if (user.loaded && user.isAnonymous) {
      utilities.cache
        .get("fg-agent")
        .then(featureUsageInCache => {
          const usersFeatureUsageCount = user.profile?.featureGate?.agent;

          if (featureUsageInCache && usersFeatureUsageCount === undefined) {
            user.data.update({ featureGate: { agent: featureUsageInCache } });

            if (usageAllowed <= featureUsageInCache) {
              analytics.track.event("Feature Gate", { agent: true });
            }
          } else if (usersFeatureUsageCount && featureUsageInCache === null) {
            utilities.cache.set("fg-agent", usersFeatureUsageCount);
          }
        })
        .finally(() => setTimeout(setCacheRead, 3e3, true));
    }
  }, [analytics, utilities, user, user.loaded, user.isAnonymous, usageAllowed]);

  // track usage for anonymous users
  useEffect(() => {
    if (cacheRead && isGated === false) {
      const currentUsage = agent.history.filter(
        message => message.role === "user"
      ).length;
      const savedUsageCount = user.profile?.featureGate?.agent ?? 0;

      if (savedUsageCount < currentUsage) {
        user.data.update({ featureGate: { agent: currentUsage } });
        utilities.cache.set("fg-agent", currentUsage);

        if (usageAllowed <= currentUsage) {
          analytics.track.event("Feature Gate", { agent: true });
        }
      }
    }
  }, [
    analytics,
    utilities,
    user,
    cacheRead,
    isGated,
    usageAllowed,
    agent.history
  ]);

  return isGated
    ? user.isAnonymous
      ? signInDialog
      : upgradeDialog
    : undefined;
}
