"use client";
import { useEffect } from "react";
import dynamic from "next/dynamic";

import { useStore, observer } from "../../service/mobx";

const SwipeableDrawer = dynamic(() => import("@mui/material/SwipeableDrawer"));

function BottomSheet() {
  const { device, bottomSheet } = useStore();

  // only show bottom sheet on small screens
  useEffect(() => {
    if (bottomSheet.open && device.isPhone === false) {
      bottomSheet.set.open(false);
    }
  }, [bottomSheet, device.isPhone]);

  return device.measured === false || device.isDesktop ? null : (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      hysteresis={0.1}
      anchor="bottom"
      open={bottomSheet.open}
      onClose={bottomSheet.onClose}
      slotProps={{
        root: {
          sx: {
            "& *::-webkit-scrollbar": {
              display: "none"
            }
          }
        }
      }}
      PaperProps={{
        sx: {
          px: 2,
          py: 2,
          maxHeight: "95vh",
          overflowY: "auto",
          backgroundImage: "unset",
          m: { compact: 0, large: 56 },
          // pb: bottomNav.loaded ? 10 : undefined,
          bgcolor: "var(--surface-container-low)",
          borderRadius: "var(--shape-xl-top)",
          boxShadow: "var(--elevation-1)",
          // transform: bottomSheet.open ? undefined : "0 0 0",
          // transformOrigin: "bottom center",
          // transitionProperty: "all !important",
          // // transitionDuration: "5s !important",
          // transitionTimingFunction: bottomSheet.open
          //   ? "var(--motion-easing-emphasizedDecelerate) !important"
          //   : "var(--motion-easing-emphasizedAccelerate) !important",
          // ...theme.motion.transition[bottomSheet.open ? "enter" : "exit"].perm,
          ...bottomSheet.sx
        }
      }}
      // onTransitionExited={bottomSheet.reset}
      SlideProps={{
        // appear: true,
        // mountOnEnter: true,
        // unmountOnExit: true,
        onExited: bottomSheet.reset,
        timeout: { appear: 500, enter: 500, exit: 300 }
      }}
      // TransitionComponent={Collapse}
    >
      <bottomSheet.Component />
    </SwipeableDrawer>
  );
}

export default observer(BottomSheet);
