import { reaction } from "mobx";
import { firestore } from "../firebase";
import { convertToID, watchQuery } from "../graph";

export default class Stars {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    let unsubscribe;

    reaction(
      () => (this._.user.loaded ? this._.user.uid : undefined),
      uid => {
        unsubscribe?.();

        if (uid === undefined) {
          this.set.loading();
        } else {
          unsubscribe = firestore
            .collection("lists")
            .query({
              where: [["owner", "array-contains-any", [uid]]],
              orderBy: [["modified", "desc"]]
            })
            .watch(({ docs }) => {
              const toStar = ([
                bookmarkHexId,
                { created, type, id, publisher, paperID, videoID, url }
              ]) => ({
                bookmarkHexId,
                id:
                  id ||
                  (type === "paper" && publisher && paperID
                    ? convertToID({ publisher, paperID })
                    : type === "tag" && id
                    ? id
                    : type === "video" && publisher && videoID
                    ? convertToID({ publisher, videoID })
                    : type === "url" && url
                    ? convertToID({ url })
                    : bookmarkHexId),
                type,
                created: created.toDate()
              });
              const lists = [];
              /*
              videos or entities that are missing their ID params will go missing
              */
              for (const doc of docs) {
                const { name, bookmarks, created, modified, description } =
                  doc.data();
                //
                lists.push({
                  id: doc.id,
                  name,
                  description,
                  created: created.toDate(),
                  modified: modified?.toDate(),
                  stars: Object.entries(bookmarks)
                    .map(toStar)
                    .sort((bookmark1, bookmark2) =>
                      bookmark2.created.getTime() ===
                      bookmark1.created.getTime()
                        ? bookmark1.paperID < bookmark2.paperID
                          ? -1
                          : 1
                        : bookmark2.created - bookmark1.created
                    )
                });
              }

              this.set.lists(lists);
              this.set.loading(false);
            });
        }
      }
    );

    reaction(
      () => this.starredTasks,
      async starredTasks => {
        if (starredTasks.length) {
          const sortOrder = Object.fromEntries(
            starredTasks.map(tag => [tag.id, tag.created])
          );

          watchQuery(
            {
              query: this._.gql.get("tasksById"),
              variables: {
                list: starredTasks
                  .sort((a, b) => sortOrder[a.id] - sortOrder[b.id])
                  .map(tag => tag.id)
              }
            },
            results => this.set.tasks(results?.data?.tags)
          );
        }
      }
    );
  }
  set = {
    loading: (loading = true) => {
      this.loading = loading;
    },
    lists: (lists = []) => {
      this.lists = lists;
    },
    tasks: (tasks = []) => {
      this.tasks = tasks;
    }
  };
  get starredTasks() {
    return [...this.map.values()].filter(entity => entity.type === "tag");
  }

  get quickSaves() {
    return this.lists.find(
      list => list.name === "__default__" || list.name === "Favorites"
    );
  }
  get map() {
    return new Map(
      this.lists.flatMap(list => list.stars.map(entity => [entity.id, entity]))
    );
  }
  get dictionary() {
    const dictionary = {};

    for (const { id: listId, name, stars } of this.lists) {
      dictionary[listId] = stars;
      dictionary[name.toLowerCase()] = stars;

      for (const { id } of stars) {
        dictionary[id] ??= new Set();
        dictionary[id].add(listId);
      }
    }

    return dictionary;
  }
  //  helpers
  quickSave = async ({ entity, save = true }) => {
    const quickSaveFolder =
      this.quickSaves ?? (await this.list.create("Favorites"));

    return this.list.updateBookmarks(
      entity,
      new Set(save ? [quickSaveFolder.id] : [])
    );
  };
  list = {
    create: (name, description, type) =>
      firestore.collection("lists").add({
        created: new Date(),
        modified: new Date(),
        name,
        description,
        bookmarks: {},
        type,
        owner:
          type === "Shared"
            ? [this._.user.uid, this._.org.id]
            : [this._.user.uid]
      }),
    delete: id => firestore.doc("lists", id).delete(),
    edit: (id, name, description, type) =>
      firestore.doc("lists", id).update({
        name,
        description,
        type,
        modified: new Date(),
        owner: [
          this._.user.uid
          // type === "Shared" ? this._.org.id : undefined
        ].filter(defined => defined)
      }),
    _update: (listId, bookmarkId, bookmark) =>
      firestore.doc("lists", listId).update({
        modified: new Date(),
        [`bookmarks.${bookmarkId}`]: bookmark
      }),
    updateBookmarks: async (entity, newSaveFolders) => {
      const currentlySavedTo = this.dictionary[entity.id] ?? new Set();
      const timestamp = new Date();
      const bookmark = {
        id: entity.id,
        type: entity.__typename.toLowerCase(),
        createdBy: this._.user.uid,
        created: timestamp,
        modified: currentlySavedTo.size === 0 ? undefined : timestamp
      };

      const bookmarkHexId = currentlySavedTo.size
        ? this.map.get(entity.id).bookmarkHexId
        : await this._.utilities.makeId({ id: entity.id });
      // unsave the bookmark from unselected lists

      for (const listId of currentlySavedTo) {
        if (newSaveFolders.has(listId) === false) {
          this.list._update(listId, bookmarkHexId, firestore.deleteField());
        } else {
          // else if the list is not being modified, dont write to it
          newSaveFolders.delete(listId);
        }
      }
      // add the bookmark to selected lists
      for (const listId of newSaveFolders) {
        this.list._update(listId, bookmarkHexId, bookmark);
      }
    }
  };
}
