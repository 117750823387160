"use client";
import { useContext, createContext } from "react";
import {
  toJS,
  makeObservable,
  observable,
  action,
  computed,
  transaction
} from "mobx";
import { observer } from "mobx-react-lite";

// internal APIs
import gql from "./gql";
import analytics from "./analytics";
import utilities from "./utilities";
import device from "./device";
//
import user from "./user";

// entities
import stars from "./stars";
import tasks from "./tasks";
import videos from "./videos";
// views
import reader from "./reader";
import search from "./search";
// import playground from "./playground";
import deploy from "./deploy";
// high-level components
import layout from "./layout";
import snackbar from "./snackbar";
import bottomSheet from "./bottomSheet";
import dialog from "./dialog";
import bottomNav from "./bottomNav";
import menu from "./menu";
// components
import llm from "./llm";
import agent from "./agent";
//
class GlobalStore {
  constructor() {
    const stores = {
      // APIs
      gql,
      device,
      user,
      utilities,
      analytics,
      tasks,
      stars,
      videos,
      layout,
      // views
      reader,
      search,
      deploy,
      // high level components
      bottomNav,
      dialog,
      llm,
      snackbar,
      menu,
      bottomSheet,
      agent
    };

    for (const storeName in stores) {
      var Store = stores[storeName];

      this[storeName] = new Store(makeMobxStore, this);
    }

    function makeMobxStore(store) {
      const reset = () => {
        transaction(() => {
          const setters = Object.getOwnPropertyNames(store.set);
          let i = setters.length;

          while (i--) {
            store.set[setters[i]]();
          }
        });
      };

      reset();

      const storePrototype = Object.getOwnPropertyDescriptors(
        Object.getPrototypeOf(store)
      );
      const _computed = {};

      for (const prop in storePrototype) {
        if (storePrototype[prop].get !== undefined) {
          _computed[prop] = computed;
        }
      }

      const observables = {};
      const actions = Object.keys(store.set).reduce((actions, prop) => {
        actions[prop] = action;
        observables[prop] = observable;

        return actions;
      }, {});

      makeObservable(store.set, actions);
      makeObservable(store, { ...observables, ..._computed });
      // add to JS as part of all stores

      store.toJS = toJS;
      // return generic reset
      return reset;
    }
  }
}

const context = createContext();
const globalStore = new GlobalStore();
//
export { observer };
export const useStore = () => useContext(context);
export const StoreProvider = ({ children }) => (
  <context.Provider value={globalStore}>{children}</context.Provider>
);
