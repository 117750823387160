import { reaction, transaction } from "mobx";
import { firestore, auth } from "../firebase";

export default class User {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
    //
    if (typeof window !== "undefined") {
      auth.onAuthStateChanged(session => {
        if (session) {
          this.set.session(session);
        } else {
          auth.signInAnonymously();
        }
      });
    }
    // whenever a uid changes
    // add a listener to the current user's profile
    // whenever user profile changes on the DB, sync the app with latest changes
    let unsubscribe;

    reaction(
      () => this.session.uid,
      uid => {
        // remove previous listener
        unsubscribe?.();
        // if the uid changed, add a new listener
        if (uid) {
          // react to this user's profile changing
          unsubscribe = firestore.doc("users", uid).watch(user => {
            if (user.exists()) {
              const profile = user.data();
              // assign key if new registered user
              if (this.isAnonymous === false && profile.key === undefined) {
                const sixteenRandomValues = new Uint8Array(16);

                crypto.getRandomValues(sixteenRandomValues);

                this.data.update({
                  key: Array.from(sixteenRandomValues, byte =>
                    byte.toString(16).padStart(2, "0")
                  ).join("")
                });
              }
              // remove cohort after experiment done
              if (profile.cohort === undefined) {
                this.data.update({
                  cohort: Math.random() < 0.5 ? "low" : "high"
                });
              }
              // update ui in one swoop
              transaction(() => {
                this.set.profile(profile);
                this.set.loaded(true);
              });
              // update analytics providers with user's new profile
              this._.analytics.track.user.profile();
            } else {
              // create their profile
              this.data.update({
                startDate: new Date(),
                cohort: Math.random() < 0.5 ? "low" : "high"
              });
              // add something to follow to start
              this._.stars.quickSave({
                entity: {
                  __typename: "Tag",
                  id: "eyJuYW1lIjoibGFyZ2UgbGFuZ3VhZ2UgbW9kZWwiLCJ0eXBlIjoidGFzayJ9",
                  name: "large language model",
                  description:
                    "A large language model takes in a sequence of words as input and predicts the next word in the sequence as output. It's used in applications like text generation, translation, and autocomplete features, enhancing user experience in digital platforms."
                }
              });
              // set to first time user
              this.set.firstExperience(true);
              // and track analytics
              this._.analytics.track.event("First App Open");
            }
          });
        }
      }
    );

    reaction(
      () => (this.loaded && this.isAnonymous === false ? this.uid : undefined),
      async uid => {
        if (uid) {
          // get this month's usage on select features
          const meters = await this._.analytics.track.usage.get();

          this._.analytics.set.meters(meters);
          this.set.syncedWithStripe(true);
        } else {
          this.set.syncedWithStripe();
          this._.analytics.set.meters();
        }
      }
    );
  }
  sign = {
    in: {
      google: async () => {
        try {
          const { GoogleAuthProvider, signInWithPopup } = auth;
          // sign in with Google
          const provider = new GoogleAuthProvider();
          // allow user to set google account
          provider.setCustomParameters({ prompt: "select_account" });
          // reset state between users - sorry, sign out easiest?
          this.sign.out();
          //
          await signInWithPopup(provider);

          // track
          this._.analytics.track.event("User Login", {
            provider: "google.com"
          });
        } catch (error) {
          // if its a real error
          if (error.code !== "auth/popup-closed-by-user") {
            console.error(error);
          }
          return error;
        }
      },
      discord: async () => {
        const redirect = encodeURIComponent(
          `${process.env.NEXT_PUBLIC_API_SERVER}/discord${
            process.env.NODE_ENV === "production" ? "" : "?env=development"
          }`
        );

        const authWindow = window.open(
          `https://discord.com/api/oauth2/authorize?client_id=1113179276284526672&redirect_uri=${redirect}&response_type=code&scope=identify%20email%20guilds.join%20guilds.members.read%20role_connections.write`,
          "_blank",
          "width=830,height=1000"
        );
        const pollTimer = setInterval(
          (host, regex) => {
            try {
              const { location } = authWindow.document;
              // If the auth window has been redirected back to your app, close it
              if (host === location.host && location.pathname.match(regex)) {
                clearInterval(pollTimer);
                authWindow.close();

                this._.analytics.track.event("User Login", {
                  provider: "discord.com"
                });
                this._.dialog.set.open(false);
              }
            } catch {
              //
            }
          },
          2e3,
          window.location.host,
          /success|fail/
        );
      }
    },
    out: () => {
      // track
      this._.analytics.signOut();
      // reset app state
      this.reset();
      // return promise
      return auth.signOut();
    }
  };
  data = {
    arrayRemove: firestore.arrayRemove,
    arrayUnion: firestore.arrayUnion,
    deleteField: firestore.deleteField,
    // update the user's profile
    update: data =>
      firestore
        .doc("users", this.session.uid)
        .set({ ...data, modified: new Date() }, { merge: true }),
    // CRUD a collection/doc owned by the user
    item: {
      create: (collection, data) =>
        firestore.collection(`${this.session.path}/${collection}`).add(data),
      read: (collection, key) =>
        firestore.doc(`${this.session.path}/${collection}`, key).get(),
      watch: (collection, key, callback) =>
        firestore
          .doc(`${this.session.path}/${collection}`, key)
          .watch(callback),
      set: (collection, key, data, merge = true) =>
        firestore
          .doc(`${this.session.path}/${collection}`, key)
          .set(data, { merge }),
      update: (collection, key, data) =>
        firestore.doc(`${this.session.path}/${collection}`, key).update(data),
      delete: (collection, key) =>
        firestore.doc(`${this.session.path}/${collection}`, key).delete()
    },
    query: ({ collection, ...options }, watchCallback) => {
      const query = firestore
        .collection(`${this.session.path}/${collection}`)
        .query(options);

      return watchCallback === undefined
        ? query.get()
        : query.watch(watchCallback);
    }
  };
  set = {
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    firstExperience: (firstExperience = false) => {
      this.firstExperience = firstExperience;
    },
    session: (session = {}) => {
      // cache this users firestore path
      session.path = `users/${session.uid}`;
      // set
      this.session = session;
      // for debug purposes
      // if not in iframe, you can log
      if (
        typeof window !== "undefined" &&
        window === window.parent &&
        this.session.uid
      ) {
        console.log(this);
      }
    },
    profile: (profile = {}) => {
      this.profile = profile;
    },
    syncedWithStripe: (syncedWithStripe = false) => {
      this.syncedWithStripe = syncedWithStripe;
    }
  };
  get premium() {
    const plan = this.profile.plan ?? "free";

    return (
      this.profile.nawar ||
      (this.isAnonymous === false && plan.includes("free") === false)
    );
  }
  get isAnonymous() {
    return this.session.isAnonymous ?? true;
  }
  get uid() {
    return this.session.uid;
  }
  get customerId() {
    return process.env.NODE_ENV === "production"
      ? this.profile.customerId
      : this.profile.customerIdDev || this.profile.customerId;
  }
  get key() {
    return this.profile.key;
  }
  get name() {
    return this.profile.name || this.session.displayName || "";
  }
  get firstName() {
    return this.name.split(" ")?.[0] ?? "";
  }
  get photo() {
    return (
      this.profile.photoURL ||
      this.session.providerData?.find(
        provider => provider.providerId === "google.com"
      )?.photoURL ||
      this.session.photoURL
    );
  }
  get handle() {
    return this.profile.handle || "anonymous";
  }
  async link(customFirebaseToken) {
    try {
      const {
        OAuthProvider,
        linkWithCredential,
        signInWithCustomToken,
        Auth: { currentUser: previousUser }
      } = auth;
      const wasSignedIntoGoogle = previousUser?.isAnonymous === false;
      const userCredential = await signInWithCustomToken(customFirebaseToken);
      //
      // console.log({ userCredential, wasSignedIntoGoogle });
      if (wasSignedIntoGoogle) {
        // console.log("linking");
        // If the user is not an anonymous user, link with the Discord credential
        await linkWithCredential(
          previousUser,
          OAuthProvider.credentialFromResult(userCredential)
        );
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
