"use client";
import { useEffect, useState } from "react";
import {
  Grid2,
  Stack,
  Typography,
  CardHeader,
  Avatar,
  CardMedia
} from "@mui/material";

import { useStore, observer } from "../../service/mobx";
import { graphQuery } from "../../service/graph";
import FilledCard from "./Filled";
// import ChipAssist from "../Chip/Assist";
import Popup from "../Popup";
import ChipInfo from "../Chip/Info";
import ChipFilter from "../Chip/Filter";
import { ModelIcon } from "../Icon/Model";
import { PaperIcon } from "../Icon/Papers";
import GameBadge from "../GameBadge";

export const TaskSize1 = observer(function TaskSize1({ small, tag }) {
  // const { stars } = useStore();
  // const starEvents = useStarCallback(tag);

  return (
    <HoverCard tag={tag}>
      <ChipFilter
        small={small}
        // {...starEvents}
        label={tag.name}
        // selected={stars.map.has(tag.id)}
        // StartIcon={stars.map.has(tag.id) ? OnIcon : OffIcon}
      />
    </HoverCard>
  );
});

export function TaskSize2({ tag, entity, tone }) {
  tag ??= entity;

  return (
    <Card tone={tone} task={tag.name} sx={{ p: 1 }}>
      <Header avatar badge={false} size="small" tag={tag} />
    </Card>
  );
}

export function TaskSize3({ tag, tone }) {
  //
  return (
    <Card tone={tone}>
      <Header size="medium" tag={tag} />
      {tag.description ? <Description text={tag.description} /> : null}
    </Card>
  );
}
export function TaskSize4({ tag, ...props }) {
  //
  return (
    <Card task={tag.name} {...props}>
      {/* <CardMedia
        component="img"
        src={`https://cdn.bytez.com/images/v2/${task}.png`}
        onError={event => event.target.parentElement.removeChild(event.target)}
        sx={theme => ({
          my: 2,
          height: 8 * 14,
          objectFit: "cover",
          borderRadius: "var(--shape-round)"
        })}
      /> */}
      <Header size="medium" tag={tag} />
      {tag.description ? <Description text={tag.description} /> : null}
      {/* <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          size="small"
          sx={theme => ({
            px: 2,
            height: 32,
            bgcolor: "var(--secondary-container)",
            borderRadius: "var(--shape-round)"
          })}
        >
          <Typography variant="labelMd" color="var(--secondary-on-container)">
            Learn more
          </Typography>
        </Button>
      </CardActions> */}
    </Card>
  );
}
export function CardTaskSize3({ tag, tone }) {
  const [noImg, setNoImg] = useState(false);

  return (
    <Card
      task={tag.name}
      tone={tone}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "flex-start"
        // width: "min-content"
      }}
    >
      <Grid2 container columns={10} spacing={2}>
        {noImg ? null : (
          <Grid2 size={{ compact: 10 }}>
            <CardMedia
              component="img"
              loading="lazy"
              onError={() => setNoImg(true)}
              src={`https://cdn.bytez.com/images/v2/${tag.name}.png`}
              sx={{
                aspectRatio: "16 / 9",
                borderRadius: "var(--shape-md-round)"
              }}
            />
          </Grid2>
        )}
        <Grid2 size={{ compact: 10 }}>
          <Header size="large" tag={tag} />
          <Description text={tag.description} />
        </Grid2>
      </Grid2>
    </Card>
  );
}
export function CardTaskSize4({ tag, tone }) {
  const [noImg, setNoImg] = useState(false);

  return (
    <Card
      task={tag.name}
      tone={tone}
      sx={{
        p: 0,
        maxWidth: "100%",
        boxSizing: "border-box"
      }}
    >
      <Grid2 container columns={10}>
        <Grid2 size={{ compact: 10, medium: 4 }}>
          {noImg ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              bgcolor="var(--surface-inverse-color)"
              width="100%"
              height="100%"
              borderRadius="var(--shape-md-round)"
            >
              <Typography
                component="p"
                variant="brand"
                fontSize={{ compact: 48, medium: 64 }}
                color="var(--surface-inverse-on)"
              >
                b
              </Typography>
            </Stack>
          ) : (
            <CardMedia
              component="img"
              loading="lazy"
              onError={() => setNoImg(true)}
              src={`https://cdn.bytez.com/images/v2/${tag.name}.png`}
              sx={{
                maxHeight: 240,
                borderRadius: "var(--shape-md-round)"
              }}
            />
          )}
        </Grid2>
        <Grid2
          size={{ medium: "grow" }}
          pl={{ compact: 0, medium: 2 }}
          pt={{ compact: 1, medium: 2 }}
          pb={{ compact: 4, medium: 2 }}
        >
          <Header tag={tag} />
          <Description text={tag.description} />
        </Grid2>
      </Grid2>
    </Card>
  );
}

function Header({
  tag,
  badge = true,
  avatar,
  size = "small",
  ...subheaderProps
}) {
  const pixels = size === "small" ? undefined : 56;

  return (
    <CardHeader
      avatar={
        badge && tag.scoreTrending ? (
          <GameBadge scoreTrending={tag.scoreTrending} />
        ) : avatar ? (
          <Avatar
            variant="rounded"
            sx={{
              height: pixels,
              width: pixels,
              bgcolor: "var(--surface-inverse-color)"
            }}
            src={`https://cdn.bytez.com/images/v2/${tag.name}.png`}
          >
            <Typography variant="brand" color="var(--surface-inverse-on)">
              b
            </Typography>
          </Avatar>
        ) : undefined
      }
      title={tag.name}
      titleTypographyProps={{
        color: "var(--surface-on-color)",
        textTransform: "capitalize",
        gutterBottom: size !== "small",
        typography: {
          compact: size === "small" ? "labelMd" : "titleMd",
          expanded:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleLg"
              : size === "xl"
              ? "titleLg"
              : "titleSm",
          large:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleLg"
              : size === "xl"
              ? "headlineMd"
              : "titleMd"
        }
      }}
      subheaderTypographyProps={{ component: "div" }}
      subheader={<Subheader size={size} tag={tag} {...subheaderProps} />}
      sx={{
        p: 0,
        alignItems: "flex-start",
        "& .MuiCardHeader-action": {
          m: 0
        }
      }}
    />
  );
}

export function Subheader({ tag, count = true, size }) {
  const { utilities } = useStore();
  const items = [
    count && tag.count?.models
      ? [`${utilities.formatNumber(tag.count.models ?? 0)} models`, ModelIcon]
      : undefined,
    count && tag.count?.papers
      ? [`${utilities.formatNumber(tag.count.papers ?? 0)} papers`, PaperIcon]
      : undefined
    // count && tag.count?.stars
    //   ? [`${utilities.formatNumber(tag.count.stars ?? 0)} stars`, OffIcon]
    //   : undefined
  ].filter(defined => defined);

  return (
    <Stack
      // useFlexGap
      spacing={1}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
    >
      {items.map(([string, Icon]) => (
        <ChipInfo key={string} size={size} Icon={Icon} label={string} />
      ))}
    </Stack>
  );
}
const Description = ({ text, size = "Lg" }) => (
  <Typography
    pt={2}
    maxWidth="65ch"
    variant="bodyMd"
    color="var(--surface-on-color)"
    sx={theme => theme.typography.lineClamp(size === "Lg" ? 7 : 5)}
  >
    {text}
  </Typography>
);
function Card({ task, tone = "var(--surface-color)", children, sx }) {
  const { analytics } = useStore();

  return (
    <FilledCard
      tone={tone}
      href={`/search?type=papers&task=${task}`}
      onClick={() => analytics.track.event("Task Click", { task })}
      sx={{ ...sx, border: 1, borderColor: "var(--outline-variant)" }}
    >
      {children}
    </FilledCard>
  );
}
export const HoverCard = observer(function HoverCard({ children, tag }) {
  const [tagNode, setNode] = useState(tag);
  const { analytics, gql, user } = useStore();

  useEffect(() => {
    if (user.loaded) {
      graphQuery({
        query: gql.get("task"),
        variables: { task: tag.name }
      }).then(results => setNode(results?.data?.tags?.[0] ?? {}));
    }
  }, [gql, user.loaded, tag.name]);

  return (
    <Popup
      disableMobile
      eventName="Task"
      Component={() => (
        <TaskSize4
          tag={tagNode}
          tone="var(--surface-container-lowest)"
          sx={{
            "&:hover, :focus": {
              bgcolor: "var(--surface-container-low)"
            }
          }}
        />
      )}
    >
      <div
        onClick={event => {
          analytics.track.event("Task Click", { task: tag.name });
          event.stopPropagation();
        }}
      >
        {children}
      </div>
    </Popup>
  );
});
