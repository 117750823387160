"use client";
import { ButtonBase } from "@mui/material";

import ToolTip from "../Tooltip";
import { _Icon } from "./index";

const ButtonFilled = ({
  selected,
  IconStart,
  IconEnd,
  tooltip,
  label = "",
  fullWidth,
  size = "medium",
  disabled = false,
  sx,
  ...props
}) => (
  <ToolTip title={tooltip} sx={fullWidth ? { width: "100%" } : undefined}>
    <ButtonBase
      disabled={disabled}
      sx={theme => ({
        px: 3,
        pl: label ? undefined : 1,
        borderRadius: "var(--shape-round)",
        width: fullWidth ? "100%" : undefined,
        height: size === "small" ? 32 : 40,
        bgcolor: disabled
          ? theme.alpha("var(--surface-on-color)", 0.12)
          : "var(--primary-color)",
        transition: theme.transitions.create("all"),
        typography: {
          compact: "labelMd",
          expanded: `label${size === "small" ? "Md" : "Lg"}`
        },
        color: disabled
          ? theme.alpha("var(--surface-on-color)", 0.38)
          : "var(--primary-on-color)",
        "&:hover": {
          boxShadow: "var(--elevation-1)",
          backgroundImage: disabled
            ? undefined
            : `linear-gradient(${theme.alpha(
                "var(--secondary-on-container)",
                0.08
              )}, ${theme.alpha("var(--secondary-on-container)", 0.08)})`
        },
        ...sx
      })}
      {...props}
    >
      {IconStart ? (
        <_Icon
          color="var(--primary-on-color)"
          Icon={IconStart}
          disabled={disabled}
        />
      ) : null}
      {label}
      {IconEnd ? (
        <_Icon
          end
          color="var(--primary-on-color)"
          Icon={IconEnd}
          disabled={disabled}
        />
      ) : null}
    </ButtonBase>
  </ToolTip>
);

export default ButtonFilled;
