"use client";
import { ButtonBase } from "@mui/material";

import ToolTip from "../Tooltip";

export default function ButtonToggle({
  selected,
  OnIcon,
  OffIcon,
  size = "medium",
  tooltip,
  sx = {},
  ...props
}) {
  const Icon = selected ? OnIcon : OffIcon;

  return (
    <ToolTip title={tooltip}>
      <ButtonBase
        sx={theme => ({
          borderRadius: "var(--shape-round)",
          width: size === "small" ? 32 : 40,
          height: size === "small" ? 32 : 40,
          bgcolor: selected
            ? "var(--primary-color)"
            : "var(--surface-container-highest)",
          transition: theme.transitions.create("all"),
          "&:hover": {
            backgroundImage: `linear-gradient(${theme.alpha(
              selected ? "var(--primary-on-color)" : "var(--primary-color)",
              0.08
            )}, ${theme.alpha(
              selected ? "var(--primary-on-color)" : "var(--primary-color)",
              0.08
            )})`,
            "& svg": {
              color: selected
                ? "var(--primary-on-color)"
                : "var(--primary-color)"
            }
          },
          ...sx
        })}
        {...props}
      >
        <Icon
          sx={() => ({
            width: size === "small" ? 16 : 24,
            height: size === "small" ? 16 : 24,
            color: selected ? "var(--primary-on-color)" : "var(--primary-color)"
          })}
        />
      </ButtonBase>
    </ToolTip>
  );
}
