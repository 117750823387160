"use client";
import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { Collapse, IconButton, Stack } from "@mui/material";
import {
  SearchRounded as ViewAllIcon,
  CheckRounded as SelectedIcon,
  MoreVertRounded as OverflowIcon
} from "@mui/icons-material";
import NewChatIcon from "@mui-symbols-material/w400/ChatAddOnRounded";
import SessionIcon from "@mui-symbols-material/w400/ModeCommentRounded";

import { useStore, observer } from "../../../service/mobx";
import { NavListItem } from "../index";
import ToolTip from "../../Tooltip";
import Menu from "./Menu";
import TimeAgo from "../../TimeAgo";
import Skeleton from "../../Skeleton";

function Sessions() {
  const [threads, setThreads] = useState([]);
  const { agent, layout, user } = useStore();
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    const threads = agent.threads.length
      ? agent.threads
      : user.profile.threads ?? [];

    setThreads(threads.slice(0, 5));
  }, [user.profile.threads, agent.threads]);

  return (
    <Stack spacing={0.5}>
      <ToolTip
        placement="right"
        title={layout.navOpen ? "" : "New chat session"}
      >
        <NavListItem
          line1="Chat"
          leading={{
            Icon: () => (
              <NewChatIcon
                sx={{
                  ml: -0.25,
                  width: 24,
                  height: 24,
                  transform: "scale(-1, 1)"
                }}
              />
            )
          }}
          trailing={
            threads.length < 2
              ? undefined
              : {
                  Icon: () => (
                    <ToolTip placement="right" title="Search your chat search">
                      <IconButton
                        href="/agent/history"
                        onClick={event => event.stopPropagation()}
                        sx={{
                          "&:hover": {
                            bgcolor: "var(--primary-container)",
                            svg: {
                              color: "var(--primary-on-container)"
                            }
                          }
                        }}
                      >
                        <ViewAllIcon sx={{ width: 20, height: 20 }} />
                      </IconButton>
                    </ToolTip>
                  )
                }
          }
          onClick={() => {
            if (pathname === "/") {
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
              setTimeout(router.push, layout.transitionDuration, "/");
            }
          }}
        />
      </ToolTip>
      <Collapse in={layout.navOpen && (layout.mountAgent || pathname === "/")}>
        <Stack spacing={0.5}>
          {threads.map(thread => (
            <Item
              key={thread.id ?? thread}
              loading={thread.id === undefined}
              thread={thread.id === undefined ? undefined : thread}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

export default observer(Sessions);

const Item = observer(function Item({ loading, thread = {} }) {
  const { agent, menu } = useStore();
  const selected = loading === false && agent.threadId === thread.id;

  return (
    <ToolTip
      placement="right"
      title={agent.threadId === thread.id ? "" : "Continue where you left off"}
    >
      <NavListItem
        disabled={agent.running}
        selected={selected}
        line1Sx={{ textTransform: "unset", typography: "bodyMd" }}
        line1={
          loading ? (
            <Skeleton borderRadius="var(--shape-xs-round)" height={32} />
          ) : (
            <TimeAgo
              date={
                thread.history.length === 0
                  ? thread.created
                  : thread.history[thread.history.length - 1].created
              }
            />
          )
        }
        leading={{
          Icon: selected
            ? SelectedIcon
            : () => <SessionIcon sx={{ width: 20, height: 20 }} />
        }}
        href="/agent"
        onClick={event => {
          if (selected) {
            event.preventDefault();
          } else {
            agent.set.threadId(thread.id);
          }
        }}
        trailing={
          selected
            ? {
                Icon: () => (
                  <IconButton
                    onClick={event => {
                      event.preventDefault();

                      menu.configure({
                        anchor: event.currentTarget,
                        Component: Menu,
                        sx: {
                          menuList: {
                            py: 0
                          },
                          paper: {
                            width: 248,
                            maxHeight: "unset",
                            borderRadius: "var(--shape-sm)"
                          }
                        },
                        options: {
                          anchorOrigin: {
                            vertical: "center",
                            horizontal: "right"
                          },
                          transformOrigin: {
                            vertical: "center",
                            horizontal: "left"
                          }
                        }
                      });
                    }}
                  >
                    <OverflowIcon />
                  </IconButton>
                )
              }
            : undefined
        }
      />
    </ToolTip>
  );
});
