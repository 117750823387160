"use client";
import { useLayoutEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { Box, Stack } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";

const AnonymousIcon = dynamic(() => import("../../Icon/Anonymous"));
const RenderMarkdownMessage = dynamic(() => import("../../Markdown/Message"));
const KnowledgePanel = dynamic(() => import("./KnowledgePanel"));
const Step = dynamic(() => import("./Step"));
const ButtonFilled = dynamic(() => import("../../Button/Filled"));
const ButtonText = dynamic(() => import("../../Button/Text"));
const BeatLoader = dynamic(() =>
  import("react-spinners").then(module => module.BeatLoader)
);
const EditIcon = dynamic(() => import("@mui/icons-material/EditRounded"));
const TextField = dynamic(() => import("@mui/material/TextField"));
const ToolTip = dynamic(() => import("../../Tooltip"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
const Fade = dynamic(() => import("@mui/material/Fade"));
const Avatar = dynamic(() => import("@mui/material/Avatar"));
const Typography = dynamic(() => import("@mui/material/Typography"));

function ChatMessages() {
  const { agent } = useStore();
  const ref = useRef();
  const lastMessage = agent.history.at(-1);

  useLayoutEffect(() => {
    if (lastMessage) {
      ref.current.scrollIntoViewIfNeeded();

      const id = setTimeout(() => ref.current.scrollIntoViewIfNeeded(), 200);

      return () => clearTimeout(id);
    }
  }, [lastMessage]);

  return (
    <Stack useFlexGap spacing={6} px={2} height="100%">
      {agent.loaded ? (
        agent.history.map((message, index) => (
          <Message
            key={message.role === "user" ? index : message.id}
            message={message}
            index={index}
          />
        ))
      ) : (
        <Loader />
      )}
      <div ref={ref} />
    </Stack>
  );
}

export default observer(ChatMessages);

function Message({ message: { role, text, citations, sources, step }, index }) {
  const [userInput, setUserInput] = useState(text);
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const { agent, user } = useStore();
  const userMessage = role === "user";
  const loading = !text;

  return (
    <Box
      p={2}
      bgcolor={
        userMessage
          ? "var(--surface-container-highest)"
          : "var(--surface-bright)"
      }
      borderRadius="var(--shape-md-round)"
      onMouseEnter={userMessage ? () => setHover(true) : undefined}
      onMouseLeave={userMessage ? () => setHover(false) : undefined}
      sx={{
        color: "var(--surface-on-color)",
        transition: "all 200ms",
        transitionTimingFunction: "var(--motion-easing-emphasized)",
        "&:hover":
          role === "user"
            ? {
                boxShadow: "var(--elevation-1)",
                bgcolor: "var(--surface-container-color)"
              }
            : undefined,
        "& a": {
          color: "var(--primary-color)",
          textDecoration: "none",
          typography: { compact: "titleSm", large: "titleMd" }
        },
        "& h1": {
          typography: "headlineLg"
        },
        "& h2": {
          typography: "headlineMd"
        },
        "& h3": {
          typography: "headlineSm"
        },
        "& h4": {
          typography: "titleLg"
        },
        "& h5": {
          typography: "titleMd"
        },
        "& h6": {
          typography: "titleSm"
        },
        "& p": {
          typography: "bodyLg"
        },
        "& li p": {
          pb: 0.5
        },
        "& .katex": {
          font: "unset",
          fontFamily: "Roboto Flex"
        }
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={userMessage ? 2 : undefined}
      >
        {loading ? <Step step={step} /> : null}
        {userMessage ? (
          <Stack spacing={1.5} direction="row" alignItems="center">
            <Avatar
              alt="photo"
              variant="rounded"
              src={userMessage ? user.photo : undefined}
              sx={{
                width: 28,
                height: 28,
                typography: "brand",
                fontWeight: "bolder",
                bgcolor: "var(--surface-inverse-color)",
                color: "var(--surface-inverse-on)"
              }}
            >
              {role === "assistant" ? "b" : <AnonymousIcon />}
            </Avatar>
            <Typography
              variant="labelLgProminent"
              color="var(--secondary-color)"
            >
              {user.isAnonymous ? "Anonymous" : user.name}
            </Typography>
          </Stack>
        ) : loading ? (
          <BeatLoader
            size={15}
            speedMultiplier={0.5}
            color="var(--primary-color)"
          />
        ) : null}
        {userMessage ? (
          <Fade in={hover && edit === false}>
            <ToolTip title="Edit prompt" placement="left">
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
            </ToolTip>
          </Fade>
        ) : null}
      </Stack>
      {edit ? (
        <Stack spacing={2} alignItems="flex-end" mt={-0.5}>
          <TextField
            autoFocus
            multiline
            fullWidth
            label="prompt"
            variant="standard"
            value={userInput}
            onChange={event => setUserInput(event.target.value)}
            sx={{
              typography: "bodyLg",
              "& .MuiInput-underline:before": {
                borderBottomColor: "var(--outline-variant)" // Change the default color
              },
              "& .MuiInput-underline:hover:before": {
                borderBottomColor: "var(--outline-color)" // Change color on hover
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "var(--primary-color)" // Change color when focused
              }
            }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <ButtonText
              label="Cancel"
              onClick={() => {
                setEdit(false);
                setUserInput(text);
              }}
            />
            <ButtonFilled
              disabled={text === userInput || !userInput.trim()}
              label="Send"
              onClick={async () => {
                const messagesToDelete = agent.history.slice(index);

                await Promise.all(
                  messagesToDelete.map(message =>
                    agent.message.delete(message.id, false)
                  )
                );

                agent.chat(userInput);
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <RenderMarkdownMessage md={text} />
      )}
      {sources?.length ? (
        <KnowledgePanel citations={citations} sources={sources} />
      ) : null}
    </Box>
  );
}

const Loader = () => (
  <Box
    m="auto"
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      width: "calc(min(100vw, 100vh) / 3.618)", // Golden ratio for size
      height: "calc(min(100vw, 100vh) / 3.618)", // Maintain a perfect circle
      borderRadius: "50%", // Circular shape
      bgcolor: "var(--surface-inverse-color)", // Dark background
      position: "relative", // Needed for layering
      overflow: "hidden", // Clip shimmer to circle
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "200%", // Ensures smooth transition
        height: "100%",
        background:
          "linear-gradient(90deg, transparent, var(--secondary-color), transparent)", // Wave gradient
        animation: "wave 5s linear 2s infinite" // Animation with delay
      },
      "@keyframes wave": {
        "0%": {
          transform: "translateX(-100%)"
        },
        "40%": {
          transform: "translateX(0%)"
        },
        "80%": {
          transform: "translateX(100%)"
        },
        "100%": {
          transform: "translateX(100%)" // Static for remaining time
        }
      }
    }}
  >
    <Typography
      position="relative" // Keeps it above the wave
      zIndex={1} // Ensure it stays above the animation
      color="var(--surface-inverse-on)" // Text color
      typography="brand" // Use typography styles
      fontSize="calc((min(100vw, 100vh) / 3.618) / 1.618)" // Font proportional to circle size
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      b
    </Typography>
  </Box>
);
