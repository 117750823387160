"use client";
import { Fragment, Suspense, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Stack, CardHeader, Box, Grid2 as Grid } from "@mui/material";
import {
  Download as DownloadIcon,
  Star as LikesIcon
} from "@mui/icons-material";

import { useStore } from "../../../service/mobx";
import FilledCard from "../Filled";
import { ModelIcon } from "../../Icon/Model";
import Widget from "./Widget";
import ToolTip from "../../Tooltip";
import NextAvatar from "../../Avatar";
import { LinkNoScroll } from "../../Link";

const Popup = dynamic(() => "../../Popup");
const ChipAssist = dynamic(() => import("../../Chip/Assist"));
const GameBadge = dynamic(() => import("../../GameBadge"));

export function ModelCardSize1({ model }) {
  return (
    <Popup maxWidth={640} Component={() => <ModelCardSize2 model={model} />}>
      <div>
        <ChipAssist label={model?.alias} onClick={() => {}} />
      </div>
    </Popup>
  );
}

export const ModelCardSize2 = ({
  entity,
  noScroll,
  model = entity,
  disabled
}) => (
  <Card
    noScroll={noScroll}
    disabled={disabled}
    model={model}
    tone="var(--surface-color)"
    sx={{
      p: 1,
      border: 1,
      pr: entity === undefined ? undefined : 2,
      borderColor: "var(--outline-variant)"
    }}
  >
    <Header
      published
      params
      size="small"
      model={model}
      task={entity === undefined}
      noGameBadge={entity !== undefined}
    />
  </Card>
);

export function ModelCardSize3({ model, disabled }) {
  return (
    <Card disabled={disabled} model={model}>
      <Header
        noGameBadge
        published
        params
        modelSize
        size="small"
        model={model}
      />
    </Card>
  );
}

export function ModelCardSize4({ model }) {
  return (
    <Card
      model={model}
      tone="var(--surface-color)"
      sx={{ border: 1, borderColor: "var(--outline-variant)" }}
    >
      <Header
        published
        params
        modelSize
        likes
        task
        downloads
        size="large"
        model={model}
      />
    </Card>
  );
}
export const ModelCardSize5 = ({ model }) => (
  <Card model={model}>
    <Header
      published
      params
      modelSize
      likes
      task
      downloads
      size="large"
      model={model}
    />
  </Card>
);
export function ModelCardSize6({ model }) {
  return (
    <Card model={model}>
      <Grid container sx={{ maxWidth: 640 }}>
        <Grid size={{ compact: 6 }} pr={2}>
          <Widget small model={model} />
        </Grid>
        <Grid
          size={{ compact: 6 }}
          display="flex"
          height="auto"
          flexDirection="column"
        >
          <Header
            noStar
            published
            params
            task
            modelSize
            size="large"
            model={model}
          />
          <Stack direction="row" justifyContent="space-between" />
        </Grid>
      </Grid>
    </Card>
  );
}
``;
function Card({ children, noScroll, disabled, model, onClick, ...cardProps }) {
  const { analytics } = useStore();

  return (
    <FilledCard
      prefetch
      href={`/docs/${model?.paper?.publisher ?? model?.org}/${
        model?.paper?.paperID ?? model?.displayName ?? model.name
      }/model`}
      disabled={disabled}
      LinkComponent={noScroll ? LinkNoScroll : undefined}
      tone="var(--surface-container-lowest)"
      onClick={() => {
        onClick?.();
        analytics.track.event("Model Click", { modelId: model.name });
      }}
      {...cardProps}
    >
      {children}
    </FilledCard>
  );
}

function Header({
  model,
  Status,
  size = "medium",
  noStar,
  noGameBadge,
  ...subheaderProps
}) {
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const imgUrl = model.repo?.hfOwner?.avatar;

      setAvatar(
        `/api/model/avatar?org=${model.org}${
          imgUrl ? `&imgUrl=${btoa(imgUrl)}` : ""
        }`
      );
    }
  }, [model]);

  return (
    <CardHeader
      avatar={
        noGameBadge !== true && model.repo?.scoreTrending ? (
          <Suspense>
            <GameBadge scoreTrending={model.repo?.scoreTrending} />
          </Suspense>
        ) : (
          <NextAvatar
            size={40}
            alt={model.org}
            src={avatar}
            variant="rounded"
            fallback={<ModelIcon sx={{ color: "var(--surface-on-variant)" }} />}
          />
        )
      }
      title={model.displayName}
      titleTypographyProps={{
        variant: "headlineMd",
        color: "var(--surface-on-color)",
        sx: theme => theme.typography.lineClamp(3),
        typography: {
          compact: size === "small" ? "labelMd" : "titleMd",
          expanded:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleSm"
              : size === "xl"
              ? "titleLg"
              : "titleSm",
          large:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleMd"
              : size === "xl"
              ? "headlineMd"
              : "titleMd"
        }
      }}
      subheader={<SubHeader model={model} size={size} {...subheaderProps} />}
      subheaderTypographyProps={{
        color: "var(--secondary-color)",
        typography: {
          compact: "bodySm",
          expanded:
            size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
        }
      }}
      action={
        model.badge ? (
          <Box
            typography="labelMd"
            mt={0.5}
            ml={{ compact: 1, expanded: 0 }}
            px={1}
            py={0.5}
            bgcolor="var(--colors-info-90)"
            color="var(--colors-info-10)"
            borderRadius="var(--shape-xs-round)"
          >
            {model.badge}
          </Box>
        ) : null
      }
      sx={{ p: 0, alignItems: "flex-start" }}
    />
  );
}

function SubHeader({
  model,
  likes = false,
  downloads = false,
  org = true,
  params = false,
  published = true,
  task = false
}) {
  const { utilities } = useStore();
  const secondary = [
    org ? model.org : undefined,
    task ? model.tasks?.[0]?.name : undefined,
    published &&
    (model.paper?.published ||
      model.repo?.created ||
      model.updated ||
      model.created)
      ? utilities.paperTime(
          model.paper?.published ||
            model.repo?.created ||
            model.updated ||
            model.created
        )
      : undefined,
    params && model.params
      ? `${utilities.formatNumber(model.params)} params`
      : undefined
  ].filter(defined => defined);

  return (
    <>
      <Stack
        useFlexGap
        py={0.5}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
      >
        {secondary.map((content, index, array) => (
          <Fragment key={index}>
            <Box
              component="span"
              color="var(--secondary-color)"
              borderRadius={1000}
            >
              {content}
            </Box>
            {index + 1 === array.length ? undefined : <Box mx={0.5}>·</Box>}
          </Fragment>
        ))}
      </Stack>
      {likes || downloads || model.badge ? (
        <Stack pt={1} direction="row" spacing={1}>
          {likes && model.repo?.likes ? (
            <ToolTip title="Likes" placement="left">
              <Stack
                spacing={0.5}
                typography="labelMd"
                pl={1}
                pr={1.25}
                py={0.5}
                direction="row"
                alignItems="center"
                borderRadius="var(--shape-xs-round)"
                bgcolor="var(--colors-neutralVariant-90)"
                color="var(--colors-neutralVariant-10)"
              >
                <LikesIcon
                  sx={{
                    width: 12,
                    height: 12,
                    color: "var(--surface-on-variant)"
                  }}
                />
                <span>{utilities.formatNumber(model.repo.likes)}</span>
              </Stack>
            </ToolTip>
          ) : null}
          {downloads && model.repo?.downloads ? (
            <ToolTip title="Downloads" placement="right">
              <Stack
                spacing={0.5}
                typography="labelMd"
                pl={1}
                pr={1.25}
                py={0.5}
                direction="row"
                alignItems="center"
                borderRadius="var(--shape-xs-round)"
                bgcolor="var(--colors-neutralVariant-90)"
                color="var(--colors-neutralVariant-10)"
              >
                <DownloadIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: "var(--surface-on-variant)"
                  }}
                />
                <span>{utilities.formatNumber(model.repo.downloads)}</span>
              </Stack>
            </ToolTip>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
}
