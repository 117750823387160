"use client";
import { Fragment } from "react";
import { Stack, CardHeader, Typography, Box, Avatar } from "@mui/material";

import { useStore } from "../../service/mobx";
import FilledCard from "../Card/Filled";
import { textRank } from "../../service/mobx/utilities";
import Popup from "../Popup";
import ChipAssist from "../Chip/Assist";
import GameBadge from "../GameBadge";
import { CardExternalPaperSize2 } from "./External";
import StackOverflow from "./../StackOverflow";
import { TaskSize1 } from "./Task";
import { menuOptionsPublisher } from "../Menu/menus/Search/Publisher";
import RenderPaperTitle from "../Markdown/PaperTitle";
import { LinkNoScroll } from "../Link";

export function PaperCardSize1({ paper, entity }) {
  paper ??= entity;

  return (
    <Popup
      Component={() => (
        <PaperCardSize4 tone="var(--surface-container-lowest)" paper={paper} />
      )}
    >
      <div>
        <ChipAssist label="Paper" onClick={() => {}} />
      </div>
    </Popup>
  );
}
// export const PaperCardSizeSm = ({
//   paper: { title, publisher, paperID },
//   index
// }) => (
//   <ChipAssist
//     small
//     label={`${index + 1} - ${title}`}
//     href={`/docs/${publisher}/${paperID}/paper`}
//   />
// );
export function PaperCardSize2({ paper, noScroll }) {
  const { reader } = useStore();

  return reader.supportedPublisher.has(paper.publisher) ? (
    <Card
      paper={paper}
      tone="var(--surface-color)"
      sx={{
        p: 1,
        border: 1,
        borderColor: "var(--outline-variant)"
      }}
      LinkComponent={noScroll ? LinkNoScroll : undefined}
    >
      <Header org publisher paper={paper} size="small" />
    </Card>
  ) : (
    <CardExternalPaperSize2 paper={paper} />
  );
}
export function PaperCardSize25({ entity: paper, noScroll }) {
  const { reader } = useStore();

  return reader.supportedPublisher.has(paper.publisher) ? (
    <Card
      paper={paper}
      tone="var(--surface-color)"
      sx={{
        p: 1,
        border: 1,
        borderColor: "var(--outline-variant)"
      }}
      LinkComponent={noScroll ? LinkNoScroll : undefined}
    >
      <Header
        org
        publisher
        paper={paper}
        size="small"
        // badge={false}
        // published={false}
      />
    </Card>
  ) : (
    <CardExternalPaperSize2 paper={paper} />
  );
}
export function PaperCardSize3({ paper, tldr = false, tone }) {
  return (
    <Card paper={paper} tone={tone}>
      <Header org publisher paper={paper} size="small" />
      <Tasks tags={paper.tags} paddingLeft={paper.scoreTrending && !tldr} />
    </Card>
  );
}
export function PaperCardSize4({ paper, tone, tldr = false }) {
  return (
    <Box>
      <Card paper={paper} tone={tone}>
        <Stack spacing={2} display="flex" justifyContent="space-between">
          <Header org authors publisher paper={paper} />
          {tldr ? <Summary size="large" paper={paper} /> : null}
          <Tasks tags={paper.tags} paddingLeft={paper.scoreTrending && !tldr} />
        </Stack>
      </Card>
    </Box>
  );
}
export function PaperCardSize5({ paper, target }) {
  return (
    <Card paper={paper} target={target}>
      <Stack spacing={2} display="flex" justifyContent="space-between">
        <Header size="large" org authors publisher paper={paper} />
        <Summary size="large" paper={paper} />
        <Tasks tags={paper.tags} />
      </Stack>
    </Card>
  );
}

function Card({
  children,
  paper,
  tone = "var(--surface-container-low)",
  ...props
}) {
  const { analytics } = useStore();
  const { publisher, paperID } = paper;

  return (
    <FilledCard
      prefetch
      href={`/docs/${publisher}/${paperID}/paper`}
      tone={tone}
      onClick={() => {
        analytics.track.event("Paper Click", { publisher, paperID });
      }}
      {...props}
    >
      {children}
    </FilledCard>
  );
}

export const Tasks = ({ small, tags, paddingLeft }) =>
  tags?.length ? (
    <Box pl={paddingLeft ? 7 : undefined}>
      <StackOverflow
        max={1}
        entityName="tag"
        small={small}
        Card={TaskSize1}
        cards={tags}
      />
    </Box>
  ) : null;

export const Header = ({
  paper,
  size = "medium",
  avatar = false,
  badge = true,
  ...subheaderProps
}) => (
  <CardHeader
    avatar={
      badge &&
      (Number.isFinite(paper.scoreTrending) ||
        Number.isFinite(paper.scoreSimilarity)) ? (
        <GameBadge
          scoreTrending={paper.scoreTrending}
          scoreSimilarity={paper.scoreSimilarity}
          models={paper.models}
        />
      ) : avatar ? (
        <Avatar
          variant="rounded"
          sx={{ bgcolor: "var(--surface-inverse-color)" }}
          src={paper.imgTask}
        >
          <Typography variant="brand" color="var(--surface-inverse-on)">
            b
          </Typography>
        </Avatar>
      ) : undefined
    }
    title={<RenderPaperTitle paperTitle={paper.title} />}
    titleTypographyProps={{
      variant: "headlineMd",
      color: "var(--surface-on-color)",
      gutterBottom: true,
      sx: theme => theme.typography.lineClamp(3),
      typography: {
        compact: size === "small" ? "labelMd" : "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "titleLg"
            : "titleSm",
        large:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "headlineMd"
            : "titleMd"
      }
    }}
    subheader={<SubHeader paper={paper} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      color: "var(--secondary-color)",
      typography: {
        compact: "bodySm",
        expanded:
          size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
      }
    }}
    sx={{ p: 0, alignItems: "flex-start" }}
  />
);
export function SubHeader({
  paper,
  org = false,
  authors = false,
  publisher = false,
  published = true
}) {
  const { utilities } = useStore();

  try {
    const authorArray = paper.authorArray ?? JSON.parse(paper.authors);
    const remainingAuthorsLength = authorArray.slice(1).length;
    var authorString = `${authorArray.slice(0, 1).join(", ")}${
      remainingAuthorsLength ? " + " + remainingAuthorsLength : ""
    }`;
  } catch {
    //
  }

  const items = [
    published && paper.published
      ? utilities.paperTime(paper.published)
      : undefined,
    org ? paper.org : undefined,
    authors ? authorString : undefined,
    publisher ? menuOptionsPublisher[paper.publisher] : undefined
  ].filter(defined => defined);

  return (
    <Stack useFlexGap direction="row" alignItems="center" flexWrap="wrap">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          {content}
          {index + 1 === array.length ? undefined : (
            <Box component="span" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
function Summary({ paper, size = "small" }) {
  const mlSummary = paper?.summaries?.[0]?.summary;
  const summary = textRank(
    mlSummary ?? paper.summary,
    size === "large" ? 3 : undefined
  );

  return summary ? (
    <Typography
      component="p"
      color="var(--surface-on-color)"
      sx={theme => ({
        typography: {
          compact: size === "small" ? "paperBody2" : "bodyMd",
          expanded: size === "small" ? "paperBody2" : "bodyLg"
        },
        ...theme.typography.lineClamp(size === "small" ? 2 : 3)
      })}
    >
      {summary}
    </Typography>
  ) : null;
}
