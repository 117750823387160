import { useEffect, useState } from "react";
import { useStore } from "../../service/mobx";

export default function TimeAgo({ date }) {
  const { utilities } = useStore();
  const [timeAgo, setTimeAgo] = useState(utilities.timeAgo(date));

  useEffect(() => {
    const { unit, millisecondsIn } = utilities.timeUntil(date);
    const trackLive = new Set(["ms", "second", "minute", "hour"]).has(unit);

    if (trackLive) {
      const id = setTimeout(() => {
        setTimeAgo(utilities.timeAgo(date));
      }, millisecondsIn[unit]);

      return () => clearTimeout(id);
    }
  }, [utilities, date, timeAgo]);

  return timeAgo;
}
