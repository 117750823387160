import {
  MenuItem as ListItemButton,
  ListItemText,
  ListItemIcon
} from "@mui/material";

const MenuItem = ({
  sx,
  selected,
  label,
  secondary,
  TrailingIcon,
  LeadingIcon,
  ...props
}) => (
  <ListItemButton
    sx={{
      ...sx,
      height: 48,
      px: 1.5,
      bgcolor: selected ? "var(--secondary-container)" : undefined,
      "&:focused": {
        backgroundImage: theme =>
          `linear-gradient(${theme.alpha(
            "var(--surface-on-color)",
            0.08
          )}, ${theme.alpha("var(--surface-on-color)", 0.08)})`
      }
    }}
    {...props}
  >
    {/* todo, double check specs on the Icons */}
    {LeadingIcon ? (
      <ListItemIcon>
        <LeadingIcon sx={{ color: "var(--surface-on-variant)" }} />
      </ListItemIcon>
    ) : null}
    <ListItemText
      primary={label}
      secondary={secondary}
      slotProps={{
        primary: {
          variant: "labelLg",
          textTransform: "capitalize",
          color: selected
            ? "var(--secondary-on-container)"
            : "var(--surface-on-color)",
          sx: theme => theme.typography.lineClamp(2)
        }
      }}
    />
    {TrailingIcon ? (
      <ListItemIcon>
        <TrailingIcon sx={{ color: "var(--surface-on-variant)" }} />
      </ListItemIcon>
    ) : null}
  </ListItemButton>
);

export default MenuItem;
