import { useRouter } from "next/navigation";
import { Box, Container, IconButton, Stack } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ExpandIcon from "@mui-symbols-material/w400/ExpandContentOutlined";
import { motion } from "motion/react";

import { observer, useStore } from "../../service/mobx";
import ToolTip from "../Tooltip";
import ButtonToggle from "../Button/Toggle";

const MotionBox = motion.create(Box);
const MotionStack = motion.create(Stack);
const MotionContainer = motion.create(Container);

function PaneRight({ children }) {
  const { layout } = useStore();
  const router = useRouter();
  const isFullscreen = layout.mountAgent === false;
  const ease = [0.2, 0, 0, 1.0];

  return (
    <MotionBox
      layout
      transition={{
        ease,
        duration: layout.transitionDuration / 1e3
      }}
      sx={{
        willChange: "transform",
        position: isFullscreen ? "fixed" : "sticky",
        top: isFullscreen ? 0 : { expanded: 16, large: 24 },
        left: layout.navOpen ? 280 : 72,
        mt: isFullscreen ? undefined : "-72px",
        right: 0,
        overflowX: "hidden",
        transition: `background-color 2s ease, border-radius 2s ease`,
        height: isFullscreen
          ? "100vh" // effectively 100vh
          : {
              expanded: "calc(100vh - 16px * 2)",
              large: "calc(100vh - 24px * 2)"
            },
        overflowY: "scroll",
        boxShadow: "var(--elevation-2)",
        bgcolor: isFullscreen
          ? "var(--surface-container-high)"
          : "var(--surface-container-low)",
        borderRadius: isFullscreen
          ? "var(--shape-xl-top)"
          : "var(--shape-xl-round)",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {/* Action Bar */}
      <MotionStack
        layout
        position="sticky"
        top={0}
        direction="row"
        spacing={1}
        bgcolor={{
          compact: "inherit",
          extraLarge: isFullscreen ? "transparent" : "inherit"
        }}
        zIndex={2}
        p={isFullscreen ? 2.5 : 1}
        sx={theme => theme.fadeEdge("bottom", 85)}
      >
        <ToolTip placement={isFullscreen ? "bottom" : "top"} title="Close">
          <IconButton
            onClick={() => {
              if (isFullscreen) {
                layout.set.mountAgent(true);
              }

              layout.set.mountContent(false);

              setTimeout(router.push, layout.transitionDuration, "/agent", {
                scroll: false
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </ToolTip>
        <ToolTip
          placement={isFullscreen ? "bottom" : "top"}
          title={`${isFullscreen ? "Exit fullscreen" : "Fullscreen"}`}
        >
          <ButtonToggle
            OffIcon={ExpandIcon}
            OnIcon={ExpandIcon}
            selected={isFullscreen}
            onClick={() => {
              layout.set.navOpen(false);
              layout.set.mountAgent(isFullscreen ? true : false);
              layout.set.maxWidth(isFullscreen ? false : "expanded");
            }}
          />
        </ToolTip>
      </MotionStack>

      {/* Scrollable Content */}
      <MotionContainer
        disableGutters
        layout
        transition={{ ease, duration: 0.25 }}
        maxWidth={isFullscreen ? "large" : false}
        sx={{
          flex: 1,
          width: "100%",
          willChange: "transform",
          borderRadius: "var(--shape-xl-top)",
          p: { compact: 0, expanded: 2, large: 3 },
          pr: { compact: 0, expanded: 0, large: 3 },
          bgcolor: "var(--surface-container-lowest)"
        }}
      >
        {children}
      </MotionContainer>
    </MotionBox>
  );
}

export default observer(PaneRight);
