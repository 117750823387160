import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import {
  CheckRounded as CheckIcon,
  Close as CloseIcon
} from "@mui/icons-material";
import LimitedIcon from "@mui-symbols-material/w400/CloseSmallRounded";

import { useStore, observer } from "../../../../service/mobx";
import ButtonFilled from "../../../Button/Filled";

const Confetti = dynamic(() => import("react-confetti"));

export default function useDialogUpgrade(
  cta = "You've reached our free-tier monthly limit"
) {
  const { dialog } = useStore();
  const useDialogUpgrade = useCallback(() => {
    dialog.configure({
      Content: () => <Content cta={cta} />,
      props: { fullWidth: true, maxWidth: "medium" },
      sx: {
        paper: {
          p: 0,
          width: undefined,
          maxHeight: undefined,
          bgcolor: "var(--dark-surface-container-lowest)"
        }
      }
    });
  }, [dialog, cta]);

  return useDialogUpgrade;
}
const Content = observer(function Content({ cta }) {
  const [tabInFocus, setTabInFocus] = useState(true);
  const [upgradeUrl, setUrl] = useState({});
  const { dialog, device, user, utilities } = useStore();

  useEffect(() => {
    if (user.syncedWithStripe) {
      utilities.stripe("PUT", JSON.stringify({ upgrade: true })).then(setUrl);
    }
  }, [utilities, user.syncedWithStripe]);

  useEffect(() => {
    dialog.set.sx({
      paper: {
        p: 0,
        width: undefined,
        maxHeight: undefined,
        bgcolor: user.premium
          ? "var(--colors-success-50)"
          : "var(--dark-surface-container-lowest)",
        transition: theme => theme.transitions.create("all", { duration: 10e3 })
      }
    });

    if (user.premium) {
      const celebrate = () =>
        setTabInFocus(document.visibilityState === "visible");

      document.addEventListener("visibilitychange", celebrate);
      return () => document.removeEventListener("visibilitychange", celebrate);
    }
  }, [dialog, user.premium]);

  return tabInFocus && user.premium ? (
    <Stack alignItems="center" justifyContent="center" height="500px">
      <Confetti style={{ maxHeight: "100%", width: "100%" }} />
      <Typography variant="displayLg" color="var(--colors-success-90)">
        Premium unlocked 🥳
      </Typography>
      <Typography variant="titleLg" color="var(--colors-success-99)">
        Thank you for being a supporter. Enjoy unlimited access
      </Typography>
    </Stack>
  ) : (
    <Box
      textAlign="center"
      py={{ compact: 1, expanded: 3 }}
      px={{ compact: 1, expanded: 10 }}
    >
      {device.isPhone ? (
        <Stack pt={2} direction="row" justifyContent="flex-end">
          <IconButton onClick={dialog.toggle}>
            <CloseIcon sx={{ color: "var(--dark-surface-on-color)" }} />
          </IconButton>
        </Stack>
      ) : null}
      <Typography
        align="center"
        color="var(--dark-tertiary-color)"
        sx={{ textWrap: "balance", pt: 4 }}
        typography={{ compact: "headlineMd", expanded: "headlineLg" }}
      >
        Upgrade for unlimited access
      </Typography>
      <Typography
        align="center"
        color="var(--dark-tertiary-color)"
        sx={{
          pb: 4,
          textWrap: "balance",
          typography: { compact: "titleMd", expanded: "titleLg" }
        }}
      >
        {cta}
      </Typography>
      <Stack direction={device.isPhone ? "column" : "row"} spacing={3}>
        {plans.map((plan, index) => (
          <Plan key={index} plan={plan} upgradeUrl={upgradeUrl.url} />
        ))}
      </Stack>
    </Box>
  );
});
function Plan({ upgradeUrl, plan: { title, features = [], cta, price } }) {
  const premium = title === "Premium";

  return (
    <Box
      flex={1}
      border={0.5}
      overflow="hidden"
      sx={theme => ({
        borderRadius: "var(--shape-xl-round)",
        boxShadow: premium
          ? `${theme.alpha(
              "var(--dark-primary-on-container)",
              0.2
            )} 0px 2px 1px -1px, ${theme.alpha(
              "var(--dark-primary-on-container)",
              0.15
            )} 0px 1px 1px 0px,${theme.alpha(
              "var(--dark-primary-on-container)",
              0.12
            )} 0px 1px 3px 0px`
          : undefined
      })}
    >
      <Box
        p={3}
        bgcolor={
          premium
            ? "var(--dark-primary-container)"
            : "var(--dark-surface-container-low)"
        }
        borderRadius="var(--shape-xl-top)"
      >
        <Typography
          pb={3}
          typography={{ compact: "headlineLg", expanded: "titleLg" }}
          color={
            premium
              ? "var(--dark-primary-on-container)"
              : "var(--dark-surface-on-color)"
          }
        >
          {title}
        </Typography>
        <Box height={56}>
          {price === undefined ? null : (
            <>
              <Typography
                component="span"
                variant="displayMd"
                color="var(--dark-surface-on-color)"
                fontWeight={200}
              >
                ${price}
              </Typography>
              <Typography
                component="span"
                variant="bodyLg"
                color="var(--dark-surface-on-variant)"
              >
                {" "}
                / month
              </Typography>
            </>
          )}
        </Box>
        <Box pt={3}>
          {cta ? (
            <ButtonFilled
              fullWidth
              href={upgradeUrl}
              label={cta}
              target="_blank"
              disabled={upgradeUrl === undefined}
              sx={{
                height: 56,
                borderRadius: "var(--shape-md-round)",
                typography: "titleLg",
                color: "var(--dark-primary-color)",
                bgcolor: "var(--dark-primary-on-color)",
                transition: theme => theme.transitions.create("all"),
                "&:hover": {
                  bgcolor: "var(--dark-primary-color)",
                  color: "var(--dark-primary-on-color)"
                }
              }}
            />
          ) : (
            <Box height={32} />
          )}
        </Box>
      </Box>
      <Stack
        p={3}
        spacing={3}
        height="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
        bgcolor="var(--dark-surface-color)"
        sx={{ borderRadius: "var(--shape-xl-bottom)" }}
      >
        {features.map(({ features }, index) => (
          <div key={index}>
            <Stack useFlexGap spacing={1}>
              {features.map(text => (
                <Stack
                  useFlexGap
                  spacing={2}
                  key={text}
                  alignItems="center"
                  direction="row"
                >
                  {text.includes("Limited") ? (
                    <LimitedIcon
                      sx={{
                        color: theme =>
                          theme.alpha("var(--dark-surface-on-color)", 0.3)
                      }}
                    />
                  ) : (
                    <CheckIcon sx={{ color: "var(--dark-surface-on-color)" }} />
                  )}

                  <Typography
                    align="left"
                    variant="bodyMd"
                    color="var(--dark-surface-on-color)"
                  >
                    {text}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}
const plans = [
  {
    title: "Premium",
    cta: "Upgrade",
    price: 10,
    features: [
      {
        features: [
          "Unlimited paper summaries, key insights, and more",
          "Unlimited paper audio and AI Research Agent",
          "Run AI models in Python, JS, Julia, and more"
        ]
      }
    ]
  }
];
