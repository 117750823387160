import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Box, Stack, Switch, TextField, Typography } from "@mui/material";
//
import { useStore } from "../../../../../service/mobx";
import ButtonOutlined from "../../../../../component/Button/Outlined";
import ButtonFilled from "../../../../../component/Button/Filled";

const Screenshot = dynamic(() => import("./Screenshot"));

export const dialogFeedback = { Content, headline: "Send feedback" };

function Content() {
  const [image, setImage] = useState();
  const [content, setContent] = useState("");
  const [isFeedback, setIsFeedback] = useState(true);
  const [takeScreenshot, setTakeScreenshot] = useState(false);
  const { analytics, dialog, snackbar, user } = useStore();

  useEffect(() => {
    if (takeScreenshot) {
      document
        .querySelector(".MuiDialog-root")
        .style.setProperty("display", "none");
    } else if (image) {
      document.querySelector(".MuiDialog-root").style.removeProperty("display");
    }
  }, [takeScreenshot, image]);

  useEffect(() => {
    dialog.set.headline(`Send ${isFeedback ? "feedback" : "bug report"}`);
  }, [dialog, isFeedback]);

  useEffect(
    () => () =>
      document
        .querySelector(".MuiDialog-root")
        ?.style.removeProperty("display"),
    []
  );

  return (
    <Stack
      spacing={2}
      color="var(--surface-on-color)"
      sx={theme => ({
        "& *": {
          transition: theme.transitions.create("all"),
          ...theme.motion.transition.enterPerm
        }
      })}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          variant="labelLg"
          color={isFeedback ? undefined : "var(--primary-color)"}
        >
          bug
        </Typography>
        <Switch
          sx={{
            "& .MuiButtonBase-root": {
              color: "var(--primary-color)"
            }
          }}
          checked={isFeedback}
          onChange={(_, value) => setIsFeedback(value)}
        />
        <Typography
          variant="labelLg"
          color={isFeedback ? "var(--primary-color)" : undefined}
        >
          feedback
        </Typography>
      </Stack>
      <TextField
        autoFocus
        multiline
        value={content}
        variant="filled"
        label={
          isFeedback ? "Share your ideas ..." : "Describe what's wrong ..."
        }
        sx={{
          borderRadius: "var(--shape-md-round)",
          bgcolor: "var(--surface-container-highest)"
        }}
        InputProps={{
          disableUnderline: true,
          sx: { borderRadius: "var(--shape-md-round)" }
        }}
        onChange={event => setContent(event.target.value)}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ButtonOutlined
          label={`${image ? "Remove" : "Add"} screenshot`}
          onClick={() => (image ? setImage() : setTakeScreenshot(true))}
        />
        <ButtonFilled
          disabled={content === ""}
          label="Send"
          onClick={async () => {
            const { userAgent, cookieEnabled, languages, userAgentData } =
              navigator;

            dialog.toggle();
            snackbar.notify({ text: "Feedback sent" });
            analytics.track.event("User Feedback");

            await fetch("/api/feedback", {
              method: "POST",
              body: JSON.stringify({
                content,
                isFeedback,
                image,
                url: location.href,
                user: {
                  uid: user.uid,
                  name: user.session.displayName,
                  email: user.session.email,
                  avatar: user.session.photoURL
                },
                device: {
                  userAgent,
                  cookieEnabled,
                  mobile: userAgentData?.mobile,
                  connection: navigator.connection?.effectiveType,
                  language: languages?.join(" "),
                  os: userAgentData?.platform ?? navigator.platform,
                  browser: userAgentData?.brands
                    ?.map(({ brand, version }) => `${brand} ${version}`)
                    .join(" ")
                }
              })
            });
          }}
        />
      </Stack>
      <Screenshot
        setImage={setImage}
        takeScreenshot={takeScreenshot}
        setScreenshot={setTakeScreenshot}
      />
      {image ? (
        <Box
          mt={4}
          component="img"
          src={image}
          width="100%"
          height="100%"
          borderRadius="var(--shape-md-round)"
        />
      ) : null}
    </Stack>
  );
}
