"use client";
import localFont from "next/font/local";
import { Roboto_Flex, Roboto_Serif, Fira_Code } from "next/font/google";
import { createTheme, alpha } from "@mui/material/styles";

import { NextLink } from "../component/Link";

const FIRA_CODE = Fira_Code({ subsets: ["latin"], display: "swap" });
const ROBOTO_FLEX = Roboto_Flex({ subsets: ["latin"], display: "swap" });
const ROBOTO_SERIF = Roboto_Serif({ subsets: ["latin"], display: "swap" });
const FF_COCON = localFont({ src: "./ff-cocon-pro.woff2", display: "swap" });
const cssVariables = /var\((--[\w-]+)\)/;

export const theme = createTheme({
  alpha(color, percent) {
    if (color.startsWith("var(")) {
      const cssVariable = color.match(cssVariables)?.[1].slice(2).split("-");

      for (let key, namespace, i = 0, j = cssVariable.length; i < j; ) {
        key = cssVariable[i++];

        if (i === 1) {
          namespace = this[key] ?? this.palette[key];
        } else if (i === j) {
          color = namespace[key];
        } else {
          namespace = namespace[key];
        }
      }
    }

    return alpha(color, percent);
  },
  fadeEdge: (direction = "bottom", percent = 90, degree) => ({
    WebkitMaskImage: `linear-gradient(${
      degree || `to ${direction}`
    }, #000 ${percent}%, transparent)`
  }),
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "& html": {
          // scrollbarColor: "var(--tertiary-color) transparent",
          "*::-webkit-scrollbar": {
            width: 16
          },
          "*::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "var(--scroll)"
          },
          "*::-webkit-scrollbar-corner": {
            backgroundColor: "transparent"
          },
          "*:focus": {
            outline: "none"
          }
        },
        "& body": {
          width: "100vw",
          overflowX: "hidden",
          backgroundColor: "var(--surface-dim)"
        },
        "@keyframes shimmer": {
          to: {
            backgroundPositionX: "0%"
          }
        },
        "@keyframes pulse": {
          "0%": {
            backgroundColor: "var(--surface-container-low)"
          },
          "12.5%": {
            backgroundColor: "var(--surface-container-color)"
          },
          "25%": {
            backgroundColor: "var(--surface-container-high)"
          },
          "37.5%": {
            backgroundColor: "var(--surface-container-highest)"
          },
          "50%": {
            backgroundColor: "var(--surface-container-highest)"
          },
          "62.5%": {
            backgroundColor: "var(--surface-container-high)"
          },
          "75%": {
            backgroundColor: "var(--surface-container-color)"
          },
          "87.5%": {
            backgroundColor: "var(--surface-container-low)"
          },
          "100%": {
            backgroundColor: "var(--surface-container-low)"
          }
        }
      }
    },
    MuiLink: { defaultProps: { component: NextLink } },
    MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },
    MuiIconButton: {
      styleOverrides: { root: { color: "var(--surface-on-variant)" } }
    },
    MuiDivider: {
      styleOverrides: { root: { borderColor: "var(--outline-variant)" } }
    },
    MuiTypography: {
      styleOverrides: { root: { color: "var(--surface-on-color)" } }
    }
  },
  cssVariables: {
    cssVarPrefix: "",
    colorSchemeSelector: "data-color-scheme"
  },
  typography: (() => {
    const robotoFlex = `${ROBOTO_FLEX.style.fontFamily}, Helvetica, Arial, sans-serif`;

    return {
      fontFamily: robotoFlex,
      serif: ROBOTO_SERIF.style.fontFamily,
      code: {
        fontFeatureSettings: '"liga" 1, "calt" 1',
        fontFamily: `${FIRA_CODE.style.fontFamily}, Consolas, Monaco, 'Andale Mono', 'Lucida Console', monospace`
      },
      brand: {
        fontFamily: `${FF_COCON.style.fontFamily}, sans-serif`
      },
      displayLg: {
        fontFamily: robotoFlex,
        lineHeight: "64px",
        fontSize: 57,
        fontWeight: 400,
        letterSpacing: -0.25
      },
      displayMd: {
        fontFamily: robotoFlex,
        lineHeight: "52px",
        fontSize: 45,
        letterSpacing: 0,
        fontWeight: 400
      },
      displaySm: {
        fontFamily: robotoFlex,
        lineHeight: "44px",
        fontSize: 36,
        letterSpacing: 0,
        fontWeight: 400
      },
      headlineLg: {
        fontFamily: robotoFlex,
        lineHeight: "40px",
        fontSize: 32,
        letterSpacing: 0,
        fontWeight: 400
      },
      headlineMd: {
        fontFamily: robotoFlex,
        lineHeight: "36px",
        fontSize: 28,
        letterSpacing: 0,
        fontWeight: 400
      },
      headlineSm: {
        fontFamily: robotoFlex,
        lineHeight: "32px",
        fontSize: 24,
        letterSpacing: 0,
        fontWeight: 400
      },
      titleLg: {
        fontFamily: robotoFlex,
        lineHeight: "28px",
        fontSize: 22,
        letterSpacing: 0,
        fontWeight: 400
      },
      titleMd: {
        fontFamily: robotoFlex,
        lineHeight: "24px",
        fontSize: 16,
        letterSpacing: 0.15,
        fontWeight: 500
      },
      titleSm: {
        fontFamily: robotoFlex,
        lineHeight: "20px",
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: 500
      },
      labelLg: {
        fontFamily: robotoFlex,
        lineHeight: "20px",
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: 500
      },
      labelLgProminent: {
        fontFamily: robotoFlex,
        lineHeight: "20px",
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: 700
      },
      labelMd: {
        fontFamily: robotoFlex,
        lineHeight: "16px",
        fontSize: 12,
        letterSpacing: 0.05,
        fontWeight: 500
      },
      labelMdProminent: {
        fontFamily: robotoFlex,
        lineHeight: "16px",
        fontSize: 12,
        letterSpacing: 0.05,
        fontWeight: 700
      },
      labelSm: {
        fontFamily: robotoFlex,
        lineHeight: "16px",
        fontSize: 11,
        letterSpacing: 0.05,
        fontWeight: 500
      },
      bodyLg: {
        fontFamily: robotoFlex,
        lineHeight: "24px",
        fontSize: 16,
        letterSpacing: 0.5,
        fontWeight: 400
      },
      bodyMd: {
        fontFamily: robotoFlex,
        lineHeight: "20px",
        fontSize: 14,
        letterSpacing: 0.25,
        fontWeight: 400
      },
      bodySm: {
        fontFamily: robotoFlex,
        lineHeight: "16px",
        fontSize: 12,
        letterSpacing: 0.4,
        fontWeight: 400
      },
      button: {
        textTransform: "unset",
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0
      },
      paperBody1: {
        lineHeight: "24px",
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.5,
        fontFamily: ROBOTO_SERIF.style.fontFamily
      },
      paperBody2: {
        lineHeight: "20px",
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.25,
        fontFamily: ROBOTO_SERIF.style.fontFamily
      },
      paperHighlight: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "18px",
        fontFamily: `New York Medium, ${ROBOTO_SERIF.style.fontFamily}, serif`
      },
      lineClamp: lines => ({
        lineClamp: lines,
        WebkitLineClamp: lines,
        textWrap: "balance",
        wordBreak: "break-word",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical"
      })
    };
  })(),
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: "#FFFBFE",
          paper: "#FFFBFE"
        },
        primary: {
          main: "#6750A4"
        },
        secondary: {
          main: "#625B71"
        }
      },
      primary: {
        main: "#6750A4",
        color: "#6750A4",
        container: "#EADDFF",
        inverse: "#D0BCFF",
        on: { color: "#fff", container: "#21005D" }
      },
      secondary: {
        main: "#625B71",
        color: "#625B71",
        container: "#E8DEF8",
        on: { color: "#fff", container: "#1D192B" }
      },
      tertiary: {
        color: "#7D5260",
        container: "#FFD8E4",
        on: { color: "#fff", container: "#31111D" }
      },
      error: {
        main: "#B3261E",
        color: "#B3261E",
        container: "#F9DEDC",
        on: { color: "#fff", container: "#410E0B" }
      },
      outline: { color: "#79747E", variant: "#C4C7C5" },
      surface: {
        inverse: { color: "#313033", on: "#F4EFF4" },
        color: "#FFFBFE",
        variant: "#E7E0EC",
        on: { color: "#1C1B1F", variant: "#49454F" },
        bright: "#FEF7FF",
        dim: "#DED8E1",
        container: {
          lowest: "#FFFFFF",
          low: "#F7F2FA",
          color: "#F3EDF7",
          high: "#ECE6F0",
          highest: "#E6E0E9"
        }
      },
      paperImg: "invert(0)",
      scroll: "rgba(125, 82, 96, 0.2)"
    },
    dark: {
      palette: {
        background: {
          default: "#141218",
          paper: "#141218"
        },
        primary: {
          main: "#D0BCFF"
        },
        secondary: {
          main: "#CCC2DC"
        }
      },
      primary: {
        color: "#D0BCFF",
        inverse: "#6750A4",
        container: "#4F378B",
        on: { color: "#381E72", container: "#EADDFF" }
      },
      secondary: {
        color: "#CCC2DC",
        container: "#4A4458",
        on: { color: "#332D41", container: "#E8DEF8" }
      },
      tertiary: {
        color: "#EFB8C8",
        container: "#633B48",
        on: { color: "#492532", container: "#FFD8E4" }
      },
      error: {
        color: "#F2B8B5",
        container: "#8C1D18",
        on: { color: "#601410", container: "#F9DEDC" }
      },
      outline: { color: "#938F99", variant: "#49454F" },
      surface: {
        inverse: { color: "#E6E0E9", on: "#322F35" },
        color: "#141218",
        variant: "#49454F",
        on: { color: "#E6E0E9", variant: "#CAC4D0" },
        bright: "#3B383E",
        dim: "#141218",
        container: {
          lowest: "#0F0D13",
          low: "#1D1B20",
          color: "#211F26",
          high: "#2B2930",
          highest: "#36343B"
        }
      },
      paperImg: "invert(1)",
      scroll: "rgba(239, 184, 200,0.2)"
    }
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 840,
      // lg: 1200,
      // xl: 1600,
      compact: 0, // compact: matches Compact, less than 600px
      medium: 600, // Medium: matches Medium, 600px to 840px
      expanded: 840, // expanded: starts at Expanded, 840px to 1200px
      large: 1200, // large: matches Large, 1200px to 1600px
      extraLarge: 1600 // extra-large: matches Extra Large, 1600px and above
    }
  },
  elevation: {
    1: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    2: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    3: "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
    4: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
    5: "rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px"
  },
  dark: {
    primary: {
      color: "#D0BCFF",
      inverse: "#6750A4",
      container: "#4F378B",
      on: { color: "#381E72", container: "#EADDFF" }
    },
    secondary: {
      color: "#CCC2DC",
      container: "#4A4458",
      on: { color: "#332D41", container: "#E8DEF8" }
    },
    tertiary: {
      color: "#EFB8C8",
      container: "#633B48",
      on: { color: "#492532", container: "#FFD8E4" }
    },
    error: {
      color: "#F2B8B5",
      container: "#8C1D18",
      on: { color: "#601410", container: "#F9DEDC" }
    },
    outline: { color: "#938F99", variant: "#49454F" },
    surface: {
      inverse: { color: "#E6E0E9", on: "#322F35" },
      color: "#141218",
      variant: "#49454F",
      on: { color: "#E6E0E9", variant: "#CAC4D0" },
      bright: "#3B383E",
      dim: "#141218",
      container: {
        lowest: "#0F0D13",
        low: "#1D1B20",
        color: "#211F26",
        high: "#2B2930",
        highest: "#36343B"
      }
    }
  },
  colors: {
    yellow: "#FFFDE7",
    amber: "#FFECB3",
    highlight: "rgba(236, 198, 73, 0.2)",
    error: {
      0: "#000000",
      10: "#410e0b",
      20: "#601410",
      30: "#8c1d18",
      40: "#b3261e",
      50: "#dc362e",
      60: "#e46962",
      70: "#ec928e",
      80: "#f2b8b5",
      90: "#f9dedc",
      95: "#fceeee",
      99: "#fffbf9",
      100: "#ffffff"
    },
    tertiary: {
      0: "#000000",
      10: "#31111d",
      20: "#492532",
      30: "#633b48",
      40: "#7d5260",
      50: "#986977",
      60: "#b58392",
      70: "#d29dac",
      80: "#efb8c8",
      90: "#ffd8e4",
      95: "#ffecf1",
      99: "#fffbfa",
      100: "#ffffff"
    },
    secondary: {
      0: "#000000",
      10: "#1d192b",
      20: "#332d41",
      30: "#4a4458",
      40: "#625b71",
      50: "#7a7289",
      60: "#958da5",
      70: "#b0a7c0",
      80: "#ccc2dc",
      90: "#e8def8",
      95: "#f6edff",
      99: "#fffbfe",
      100: "#ffffff"
    },
    primary: {
      0: "#000000",
      10: "#21005d",
      20: "#381e72",
      30: "#4f378b",
      40: "#6750a4",
      50: "#7f67be",
      60: "#9a82db",
      70: "#b69df8",
      80: "#d0bcff",
      90: "#eaddff",
      95: "#f6edff",
      99: "#fffbfe",
      100: "#ffffff"
    },
    info: {
      0: "#000000",
      10: "#05154C",
      20: "#112874",
      30: "#2D418B",
      40: "#465AA4",
      50: "#6073BF",
      60: "#7A8CDA",
      70: "#95A7F7",
      80: "#B9C4FB",
      90: "#DDE1FD",
      95: "#EFF0FE",
      99: "#FEFBFF",
      100: "#ffffff"
    },
    warning: {
      0: "#000000",
      10: "#221B04",
      20: "#3A2F09",
      30: "#534612",
      40: "#6E5D1A",
      50: "#8C7524",
      60: "#A88E31",
      70: "#C5A948",
      80: "#E2C45F",
      90: "#FAE190",
      95: "#FCF1CE",
      99: "#FEFBFF",
      100: "#FFFFFF"
    },
    success: {
      0: "#000000",
      10: "#092018",
      20: "#14372A",
      30: "#20503E",
      40: "#2E6B54",
      50: "#3B856A",
      60: "#52A887",
      70: "#69BD9B",
      80: "#85D9B6",
      90: "#A0F5D1",
      95: "#CAFDE5",
      99: "#F6FFF9",
      100: "#FFFFFF"
    },
    neutralVariant: {
      0: "#000000",
      10: "#1d1a22",
      20: "#322f37",
      30: "#49454f",
      40: "#605d66",
      50: "#79747e",
      60: "#938f99",
      70: "#aea9b4",
      80: "#cac4d0",
      90: "#e7e0ec",
      95: "#f5eefa",
      99: "#fffbfe",
      100: "#ffffff"
    },
    neutral: {
      0: "#000000",
      10: "#1c1b1f",
      20: "#313033",
      17: "#2b2930",
      22: "#36343b",
      30: "#484649",
      40: "#605d62",
      50: "#787579",
      60: "#939094",
      70: "#aeaaae",
      80: "#c9c5ca",
      90: "#e6e1e5",
      92: "#ece6f0",
      95: "#f4eff4",
      96: "#f7f2fa",
      99: "#fffbfe",
      100: "#ffffff"
    }
  },
  motion: (() => {
    const motion = {
      duration: {
        short: [50, 50, 100, 150, 200],
        medium: [250, 250, 300, 350, 400],
        long: [450, 450, 500, 550, 600],
        extraLong: [700, 700, 800, 900, 1e3]
      },
      easing: {
        standard: "cubic-bezier(0.2, 0, 0, 1.0)",
        standardAccelerate: "cubic-bezier(0.3, 0, 1.0, 1.0)",
        standardDecelerate: "cubic-bezier(0, 0, 0, 1.0)",
        emphasized: "cubic-bezier(0.05, 0, 0.25, 1)", // its recommend to use standard
        emphasizedAccelerate: "cubic-bezier(0.3, 0, 0.8, 0.15)",
        emphasizedDecelerate: "cubic-bezier(0.05, 0.7, 0.1, 1.0)"
      }
    };

    motion.transition = {
      enter: {
        perm: {
          transitionDuration: motion.duration.long[2] + "ms !important",
          transitionTimingFunction:
            motion.easing.emphasizedDecelerate + " !important"
        },
        temp: {
          transitionDuration: motion.duration.long[2] + "ms !important",
          transitionTimingFunction: motion.easing.emphasized + " !important"
        }
      },
      exit: {
        perm: {
          transitionDuration: motion.duration.short[4] + "ms !important",
          transitionTimingFunction:
            motion.easing.emphasizedAccelerate + " !important"
        },
        temp: {
          transitionDuration: motion.duration.short[4] + "ms !important",
          transitionTimingFunction: motion.easing.emphasized + " !important"
        }
      }
    };

    return motion;
  })(),
  shape: {
    round: "100px",
    xl: {
      round: "28px",
      top: "28px 28px 0 0",
      bottom: "0 0 28px 28px"
    },
    lg: {
      round: "16px",
      top: "16px 16px 0 0",
      start: "16pt 0 0 16px",
      end: "0 16px 16px 0"
    },
    md: { top: "12px 12px 0 0", round: "12px" },
    sm: "8px",
    xs: {
      round: "4px",
      top: "4px 4px 0 0"
    }
  }
});
