"use client";
import { ButtonBase } from "@mui/material";

import ToolTip from "../Tooltip";
import { _Icon } from "./index";

const ButtonText = ({
  selected,
  IconStart,
  IconEnd,
  tooltip,
  label = "",
  size = "medium",
  disabled = false,
  fullWidth,
  sx = {},
  children,
  ...props
}) => (
  <ToolTip title={tooltip}>
    <ButtonBase
      disabled={disabled}
      sx={theme => ({
        px: 3,
        pl: label ? undefined : 1,
        borderRadius: "var(--shape-round)",
        width: fullWidth ? "100%" : undefined,
        height: size === "small" ? 32 : 40,
        bgcolor: disabled
          ? theme.alpha("var(--surface-on-color)", 0.12)
          : undefined,
        transition: theme.transitions.create("all"),
        typography: {
          compact: "labelMd",
          expanded: `label${size === "small" ? "Md" : "Lg"}`
        },
        color: disabled
          ? theme.alpha("var(--surface-on-color)", 0.38)
          : "var(--primary-color)",
        "&:hover": {
          backgroundImage: `linear-gradient(${theme.alpha(
            "var(--secondary-on-container)",
            0.08
          )}, ${theme.alpha("var(--secondary-on-container)", 0.08)})`
        },
        ...sx
      })}
      {...props}
    >
      {IconStart ? (
        <_Icon color="primary" Icon={IconStart} disabled={disabled} />
      ) : null}
      {label}
      {IconEnd ? (
        <_Icon end color="primary" Icon={IconEnd} disabled={disabled} />
      ) : null}
    </ButtonBase>
  </ToolTip>
);

export default ButtonText;
