export default class Layout {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
  }
  set = {
    navOpen: (navOpen = false) => {
      this.navOpen = navOpen;
    },
    paneOpen: (paneOpen = false) => {
      this.paneOpen = paneOpen;
    },
    maxWidth: (maxWidth = "expanded") => {
      this.maxWidth = maxWidth;
    },
    mountContent: (mountContent = false) => {
      this.mountContent = mountContent;
    },
    mountAgent: (mountAgent = false) => {
      this.mountAgent = mountAgent;
    },
    checkedWhiteLabel: (checkedWhiteLabel = false) => {
      this.checkedWhiteLabel = checkedWhiteLabel;
    }
  };

  get transitionDuration() {
    return 500;
  }
  get isFullScreen() {
    return this.mountAgent === false;
  }
}
