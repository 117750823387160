import dynamic from "next/dynamic";
import { Avatar, Stack } from "@mui/material";
import {
  TrendingUp as TrendingIcon,
  Star as StartIcon
} from "@mui/icons-material";

import ToolTip from "../Tooltip";
import { RecommendedIcon } from "../Icon/Similar";

const SOTAIcon = dynamic(() => import("@mui/icons-material/MilitaryTech"));

export default function GameBadge({
  scoreTrending,
  models,
  scoreSota,
  scoreSimilarity
}) {
  scoreSota ??= models?.[0]?.scoreSota;

  if (
    Number.isFinite(scoreSota) ||
    Number.isFinite(scoreTrending) ||
    Number.isFinite(scoreSimilarity)
  ) {
    const score = scoreSota || scoreTrending || scoreSimilarity;
    const variant = scoreSota
      ? "sota"
      : scoreTrending
      ? "trending"
      : "recommended";
    const stars = Math.ceil(3 * score);

    return (
      <ToolTip
        title={
          scoreSota
            ? "Spotlight"
            : `${["", "", "Strongly", "Most"][stars]} ${
                scoreSota
                  ? "State-of-art"
                  : scoreTrending
                  ? "Trending"
                  : "Similar"
              }`
        }
      >
        <Badge variant={variant} stars={stars} />
      </ToolTip>
    );
  }
}

export const Badge = ({ variant, stars = 0 }) => {
  const Icon =
    {
      sota: SOTAIcon,
      trending: TrendingIcon,
      recommended: RecommendedIcon
    }[variant] || (() => null);

  return (
    <Avatar
      variant="rounded"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "var(--surface-container-color)"
      }}
    >
      <Icon sx={{ color: "var(--colors-success-50)" }} />
      <Stack direction="row">
        {new Array(stars).fill().map((_, index) => (
          <StartIcon
            key={index}
            sx={{ color: "warning.main", height: 10, width: 10 }}
          />
        ))}
      </Stack>
    </Avatar>
  );
};
