"use client";
import { useEffect } from "react";
// import dynamic from "next/dynamic";
import { useSearchParams, usePathname } from "next/navigation";
import { useMediaQuery, useTheme } from "@mui/material";
// import {
//   QuickreplyTwoTone as AIIcon,
//   HomeOutlined as DiscoverIcon
// } from "@mui/icons-material";

import { observer, useStore } from "../../service/mobx";
// import { OffIcon } from "../Star";

// const Agent = dynamic(() => import("../Agent/Client"));
// const ModelIconOn = dynamic(() =>
//   import("../Icon/Model").then(module => module.ModelOnIcon)
// );
// const ModelIconOff = dynamic(() =>
//   import("../Icon/Model").then(module => module.ModelIcon)
// );

function HooksAppWide() {
  const { analytics, device, layout, reader } = useStore();
  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.only("compact"));
  const isMedium = useMediaQuery(theme.breakpoints.only("medium"));
  const isExpanded = useMediaQuery(theme.breakpoints.only("expanded"));
  const isLarge = useMediaQuery(theme.breakpoints.only("large"));
  const isXL = useMediaQuery(theme.breakpoints.only("extraLarge"));
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const whiteLabel = searchParams.get("_c");
  const [, route] = pathname.split("/");
  const url = `${pathname}${
    searchParams.size === 0 ? "" : "?" + searchParams.toString()
  }`;

  useEffect(() => {
    device.set.size(
      isCompact
        ? "compact"
        : isMedium
        ? "medium"
        : isExpanded
        ? "expanded"
        : isLarge
        ? "large"
        : isXL
        ? "extraLarge"
        : ""
    );
  }, [device, isCompact, isMedium, isExpanded, isLarge, isXL]);

  useEffect(() => {
    try {
      const namespace = "history";
      const history = JSON.parse(sessionStorage.getItem(namespace) || "[]");
      const updatedHistory = new Set([url, ...history]);

      sessionStorage.setItem(
        namespace,
        JSON.stringify([...updatedHistory].slice(0, 10))
      );
    } catch (error) {
      console.error(error);
    }
  }, [url]);

  useEffect(() => {
    if (device.isDesktop === false) {
      const onScroll = () => {
        if (device.scrolling === false) {
          device.set.scrolling(true);
        }
      };
      const onScrollEnd = () => {
        if (device.scrolling) {
          setTimeout(device.set.scrolling, 500, false);
        }
      };

      window.addEventListener("scroll", onScroll, { passive: true });
      window.addEventListener("scrollend", onScrollEnd, { passive: true });

      return () => {
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("scrollend", onScrollEnd);
      };
    }
  }, [device, device.isDesktop]);

  useEffect(() => {
    switch (route) {
      case "": {
        layout.set.mountAgent(false);
        layout.set.maxWidth("expanded");
        layout.set.navOpen(device.isDesktop);

        return layout.set.navOpen;
      }
      case "agent": {
        // set context
        layout.set.mountAgent(true);
        layout.set.mountContent(false);
        layout.set.maxWidth("expanded");
        break;
      }
      case "search":
      case "docs": {
        layout.set.mountAgent(true);
        layout.set.mountContent(true);
        layout.set.maxWidth("large");
        break;
      }
    }
  }, [analytics, layout, device.isDesktop, route]);

  useEffect(() => {
    // const [whiteLabelled, setWhiteLabelled] = useState(_c);
    if (whiteLabel) {
      try {
        const { disable, related = [] } = JSON.parse(atob(whiteLabel));
        var successSignal = "1";

        reader.set.config({
          disable: disable ? new Set(disable) : undefined,
          related: new Set([...related, "authors", "audio", "conference"])
        });

        [
          document.getElementById("pane"),
          document.getElementById("rail")
        ].forEach(element => {
          if (element) {
            element.style.display = "none";
          }
        });

        document.body.style.border = "1px solid rgb(238, 238, 238)";
        // sessionStorage.setItem("whitelabelled", whiteLabel);
      } catch (error) {
        successSignal = "0";
        console.error(error);
      } finally {
        analytics.track.event("Whitelabel");
        setTimeout(window.parent?.postMessage, 2e3, successSignal, "*");
      }
    }

    layout.set.checkedWhiteLabel(true);
  }, [analytics, reader, layout, whiteLabel]);
}

export default observer(HooksAppWide);

export function useWhiteLabelling() {
  const { reader } = useStore();
  const searchParams = useSearchParams();

  return reader.whiteLabelled || searchParams.has("_c");
}
