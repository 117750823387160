import { Box, Tooltip } from "@mui/material";

const ToolTip = ({ children, title, sx, ...props }) => (
  <Tooltip
    arrow
    title={title}
    describeChild
    slotProps={{
      tooltip: {
        sx: {
          typography: "bodySm",
          color: "var(--surface-inverse-on)",
          bgcolor: "var(--surface-inverse-color)"
        }
      },
      arrow: { sx: { color: "var(--surface-inverse-color)" } }
    }}
    {...props}
  >
    <Box component="span" sx={sx}>
      {children}
    </Box>
  </Tooltip>
);

export default ToolTip;
