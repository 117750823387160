"use client";
import { useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";

import { useStore, observer } from "../../service/mobx";

function Hooks() {
  const { analytics, agent, reader, user } = useStore();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    // set context
    analytics.set.context("Agent", "View");
    // remove context when component unmounts
    return () => {
      agent.set.threadId();
      analytics.set.context();
    };
  }, [analytics, agent]);

  useEffect(() => {
    if (user.loaded && agent.threadId === undefined) {
      if (agent.loaded) {
        const firstAvailableThread = agent.threads[0]?.id;

        if (firstAvailableThread) {
          agent.set.threadId(firstAvailableThread);
        } else {
          router.push("/");
        }
      } else if (user.isAnonymous) {
        agent.thread.create();
      }
    }
  }, [user, agent, router, user.loaded, agent.loaded, agent.threadId]);

  useEffect(() => {
    const [, , publisher] = pathname.split("/");

    if (pathname === "/") {
      var viewing = { page: "Discover" };
    } else if (reader.supportedPublisher.has(publisher)) {
      if (reader.paperNode?.title === undefined) {
        return;
      }
      viewing = {
        page: "Research Paper",
        paperID: reader.paperID,
        publisher: reader.publisher,
        title: reader.paperNode.title
      };
    } else if (pathname.startsWith("/docs") && reader.article === undefined) {
      const [, , org, model] = pathname.split("/");

      viewing = { page: "Model Page", org, model };
    } else if (pathname === "/agent") {
      viewing = { page: "Chat Page" };
    } else {
      return;
    }

    agent.set.session([viewing, ...agent.session]);

    // if first view, do something
    // if (agent.pages.has(viewing.page) === false) {

    // }
    // console.log(
    //   reader.supportedPublisher.has(publisher),
    //   reader.paperNode?.title
    // );
  }, [agent, reader, reader.article, pathname]);

  useEffect(() => {
    if (agent.loaded) {
      const [viewing] = agent.session;
      // console.log(
      //   viewing,
      //   viewing?.page === "Research Paper",
      //   agent.paperHasBeenRead,
      //   reader.paperNode?.summaries?.length !== 0
      // );
      if (
        viewing?.page === "Research Paper" &&
        agent.paperHasBeenRead &&
        reader.paperNode?.summaries.length !== 0
      ) {
        agent.message.add({
          role: "assistant",
          content: `### Summary of ${reader.paperNode.title}\n\n${reader.paperNode.summaries[0].summary}`,
          metadata: ["summary", reader.paperNode.id]
        });
      }
    }
  }, [
    agent,
    agent.loaded,
    agent.session,
    agent.paperHasBeenRead,
    reader.paperNode
  ]);
}

export default observer(Hooks);
