"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";

import { useStore, observer } from "../../service/mobx";

const SwipeableBottomSheet = dynamic(
  () => import("react-swipeable-bottom-sheet"),
  { ssr: false }
);
const CloseIcon = dynamic(() => import("@mui/icons-material/Close"));
const Box = dynamic(() => import("@mui/material/Box"));
const Fade = dynamic(() => import("@mui/material/Fade"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
const Stack = dynamic(() => import("@mui/material/Stack"));

function BottomSheet() {
  const [mount, setMount] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { bottomSheet, device } = useStore();

  useEffect(() => {
    if (bottomSheet.open) {
      setExpanded(true);
      setMount(true);

      return () => {
        setMount(false);
        setExpanded(false);
      };
    }
  }, [bottomSheet, bottomSheet.open, bottomSheet.Component, device.isPhone]);

  // console.log(0, { ...state, mount }, bottomSheet.open);

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={device.measured && device.isPhone && mount}
      timeout={300}
      onExited={bottomSheet.onClose}
    >
      <Box
        height={56}
        width="100vw"
        overflow="hidden"
        sx={{
          "& *::-webkit-scrollbar": {
            display: "none"
          },
          "& .react-swipeable-view-container": {
            boxShadow: "unset !important"
          }
        }}
      >
        <SwipeableBottomSheet
          scrollTopAtClose={false}
          overflowHeight={bottomSheet.peek ? 64 : 0}
          onChange={expanded => {
            setExpanded(expanded);

            if (bottomSheet.peek === false) {
              setTimeout(setMount, 300, false);
            }
          }}
          open={expanded}
          style={{ zIndex: "var(--zIndex-modal)" }}
          bodyStyle={{
            boxShadow: "var(--elevation-5)",
            borderRadius: "var(--shape-xl-top)",
            backgroundColor: expanded
              ? "var(--surface-container-lowest)"
              : "var(--surface-container-highest)",
            border:
              bottomSheet.peek && expanded === false
                ? "1px solid var(--outline-variant)"
                : undefined,
            transition: "background-color 1s var(--motion-easing-emphasized)"
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 2,
              maxHeight: "95vh",
              overflowY: "auto",
              m: { compact: 0, large: 56 },
              top: "calc( 100vh - 56px )",
              borderRadius: "var(--shape-xl-top)",
              ...bottomSheet.sx
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              pb={2}
              px={2}
              onClick={() => setExpanded(true)}
            >
              <Box width={34} height={34} />
              <Box
                width={32}
                height={4}
                borderRadius="var(--shape-round)"
                bgcolor="var(--surface-on-variant)"
              />
              <Fade in={bottomSheet.peek && expanded === false}>
                <IconButton
                  size="small"
                  onClick={event => {
                    setMount(false);
                    event.stopPropagation();
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: 16,
                      height: 16,
                      color: "var(--secondary-color)"
                    }}
                  />
                </IconButton>
              </Fade>
            </Stack>
            <bottomSheet.Component />
          </Box>
        </SwipeableBottomSheet>
      </Box>
    </Fade>
  );
}

export default observer(BottomSheet);
