"use client";
import { Typography } from "@mui/material";

import { observer, useStore } from "../../service/mobx";

function Welcome() {
  const { user } = useStore();
  const hour = new Date().getHours();

  return (
    <Typography
      align="center"
      fontWeight="300 !important"
      typography={{ compact: "headlineLg", expanded: "displayMd" }}
    >
      {user.loaded
        ? user.isAnonymous
          ? "Welcome to Bytez"
          : `Good ${
              hour < 12 ? "morning" : hour < 17 ? "afternoon" : "evening"
            }, ${user.firstName}`
        : "Welcome"}
    </Typography>
  );
}

export default observer(Welcome);
